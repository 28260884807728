import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { UserContext } from '../Auth/UserContext';
import { API_URL } from '../../constant/constant';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function MyEarnings() {
    const { user, loading } = useContext(UserContext);
    const [formData, setFormData] = useState({
        fullName: '',
        accountNumber: '',
        bankName: '',
        ifscCode: '',
        email: '',
    });
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('');

    useEffect(() => {
        if (!loading && user) {
            setFormData((prevData) => ({
                ...prevData,
                email: user.email_id || '',
            }));
        }
    }, [loading, user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleConfirmChange = (e) => {
        setConfirmAccountNumber(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.accountNumber !== confirmAccountNumber) {
            toast.error("Account Number and Confirm Account Number do not match");
            return;
        }
        try {
            const response = await axios.post(`${API_URL}/api/creatorreg/save_bank_details`, formData);
            toast.success(response.data.message);
            setFormData({
                fullName: '',
                accountNumber: '',
                bankName: '',
                ifscCode: '',
                email: user.email_id || '',
            });
            setConfirmAccountNumber('');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(error.response.data.error);
            } else {
                toast.error("Failed to submit bank details");
            }
        }
    };

    return (
        <div className="min-h-full bg-indigo-100 flex items-center justify-center py-4 md:py-7 px-3 md:px-6">
            <ToastContainer autoClose={2000} />
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h1 className="text-2xl md:text-3xl font-bold text-center text-indigo-600 mb-6">Add Bank Details</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Account Holder's Name</label>
                        <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500" required />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Account Number</label>
                        <input type="text" name="accountNumber" value={formData.accountNumber} onChange={handleChange} className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500" required />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Confirm Account Number</label>
                        <input type="text" name="confirmAccountNumber" value={confirmAccountNumber} onChange={handleConfirmChange} className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500" required />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Bank Name</label>
                        <input type="text" name="bankName" value={formData.bankName} onChange={handleChange} className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500" required />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">IFSC Code</label>
                        <input type="text" name="ifscCode" value={formData.ifscCode} onChange={handleChange} className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500" required />
                    </div>

                    <button type="submit" className="w-full bg-indigo-600 text-white font-bold py-2 rounded-md hover:bg-indigo-700 transition duration-200">Save & Submit</button>
                </form>
            </div>
        </div>
    );
}