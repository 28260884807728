import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Pages/Auth/UserContext';
import Modal from 'react-modal';
import { FaCheck, FaClock, FaEye, FaEyeSlash, FaClipboard, FaCheckCircle } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import axios from 'axios';
import he from 'he';
import { API_URL } from '../constant/constant';
import { toast } from 'react-toastify';

const OfferDetailsModal = ({ isOpen, onRequestClose, offer }) => {
    const { loading, user } = useContext(UserContext);
    const [affiliateId, setAffiliateId] = useState(null);
    const [showTrackingLink, setShowTrackingLink] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAffiliateId = async () => {
            if (loading) return;

            try {
                const response = await axios.get(`${API_URL}/api/affiliate/get_affiliate_details`, {
                    params: { email_id: user.email_id },
                    withCredentials: true
                });

                if (response.data.exists) {
                    setAffiliateId(response.data.data.affiliate_id);
                    return;
                }
            } catch (error) {
                console.error("Error generating affiliate ID:", error);
                toast.error(error.response?.data?.error);
            }
        }
        fetchAffiliateId();
    }, []);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    }

    const handleInterestedClick = async (offerId) => {
        if (loading || isSubmitting) return;

        try {
            setIsSubmitting(true);

            const response = await axios.post(
                `${API_URL}/api/affiliate/apply_offer`,
                { offerId },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                toast.success("Offer successfully applied!", { autoClose: 2000 });
                onRequestClose();

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        } catch (error) {
            console.error('Error adding interest:', error);
            const errorMessage = error.response?.data?.message || "Failed to register interest.";

            if (error.response?.status === 401) {
                toast.error("Please login to show interest", { autoClose: 3000 });
                onRequestClose();
                navigate('/CP/brandlogin');
            } else if (error.response?.status === 400 && error.response.data.message === "Interest already added") {
                toast.info("You have already shown interest in this campaign", { autoClose: 3000 });
            } else {
                toast.error(errorMessage, { autoClose: 3000 });
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!offer) return null;

    const isAffiliateInterested = affiliateId && offer.affiliates?.[affiliateId];

    const DetailItem = ({ label, value, className = "text-gray-700 text-md" }) => {
        return (
            <p className={className}>
                <strong>{label}:</strong> {value || "N/A"}
            </p>
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Offer Details"
            className="fixed inset-0 flex items-center justify-center z-[100] mx-4"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-[99]"
            ariaHideApp={false}
        >
            <div className="bg-white rounded-lg shadow-lg w-full max-w-[95%] md:max-w-[85%] lg:max-w-[800px] relative md:ml-0 md:mr-0">
                <button
                    onClick={onRequestClose}
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 z-10"
                >
                    <IoIosClose className="text-3xl" />
                </button>
                <div className="p-4 md:p-6 lg:p-8 max-h-[90vh] overflow-y-auto">
                    <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4 md:mb-6 pr-8 text-center">
                        {offer.offer_name}
                    </h2>
                    {offer.logo && (
                        <div className="flex justify-center mb-4 md:mb-6">
                            <img
                                src={offer.logo}
                                alt={offer.offer_name}
                                className="w-full h-auto max-h-[200px] md:max-h-[300px] lg:max-h-[400px] object-contain rounded-lg"
                            />
                        </div>
                    )}
                    <div className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                            <DetailItem label="Offer ID" value={offer.offerid} />
                            <DetailItem label="Category" value={offer.category} />
                            <DetailItem label="Model" value={offer.model_advertiser} />
                            <DetailItem label="Payout" value={`${offer.price_affiliate || '0'} ${offer.currency || 'INR'}`} />
                            <DetailItem label="Advertiser ID" value={offer.advertiser_id} />
                        </div>

                        <div className="space-y-3 text-gray-700 text-md">
                            <strong>Description:</strong>
                            <p>
                                {he.decode(offer.offer_description) || 'No Description'}
                            </p>
                        </div>
                    </div>
                    <div className="mt-6 md:mt-8 flex flex-col md:flex-row justify-center items-stretch md:items-center gap-3 md:gap-6">
                        {isAffiliateInterested && offer.affiliates[affiliateId]?.tracking_url ? (
                            <>
                                <button
                                    onClick={() => setShowTrackingLink((prev) => !prev)}
                                    className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white text-base px-6 py-2.5 rounded-md transition-colors w-full md:w-auto"
                                >
                                    {showTrackingLink ? (
                                        <>
                                            <FaEyeSlash className="h-5 w-5 mr-2" /> Hide Tracking Link
                                        </>
                                    ) : (
                                        <>
                                            <FaEye className="h-5 w-5 mr-2" /> Show Tracking Link
                                        </>
                                    )}
                                </button>
                                {showTrackingLink && (
                                    <div className="mt-2 flex items-center gap-4">
                                        <p className="text-base text-gray-700 break-all">
                                            <span className="font-semibold">Tracking URL:{" "}</span>
                                            <span className="underline text-indigo-700">
                                                {offer.affiliates[affiliateId].tracking_url}
                                            </span>
                                        </p>

                                        <button
                                            onClick={() => copyToClipboard(offer.affiliates[affiliateId]?.tracking_url)}
                                            className="flex items-center gap-2 bg-gray-200 hover:bg-gray-300 text-gray-800 px-3 py-1.5 rounded-md transition-colors"
                                        >
                                            {isCopied ? (
                                                <>
                                                    <FaCheckCircle className="h-5 w-5 text-green-500" />
                                                    Copied!
                                                </>
                                            ) : (
                                                <>
                                                    <FaClipboard className="h-5 w-5" />
                                                    Copy
                                                </>
                                            )}
                                        </button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <button
                                onClick={() => handleInterestedClick(offer.offerid)}
                                disabled={isSubmitting || loading}
                                className={`flex items-center justify-center ${isSubmitting || loading
                                    ? 'bg-gray-400'
                                    : 'bg-green-500 hover:bg-green-600'
                                    } text-white text-base md:text-base px-6 py-2.5 rounded-md transition-colors w-full md:w-auto min-w-[150px]`}
                            >
                                {isSubmitting ? (
                                    <>
                                        <svg
                                            className="animate-spin h-5 w-5 mr-2 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                                            ></path>
                                        </svg>
                                        Processing...
                                    </>
                                ) : (
                                    <>
                                        <FaCheck className="h-5 w-5 mr-2" /> Interested
                                    </>
                                )}
                            </button>
                        )}
                        <button
                            onClick={onRequestClose}
                            className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 text-white text-base md:text-base px-6 py-2.5 rounded-md transition-colors w-full md:w-auto min-w-[150px]"
                        >
                            <FaClock className="mr-2" /> Later
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default OfferDetailsModal;