import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import { API_URL } from "../../constant/constant";
import { UserContext } from "../Auth/UserContext";

const GenerateAffiliate = () => {
    const { user, loading: userLoading } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [affiliateDetails, setAffiliateDetails] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        firstName: "",
        email_id: "",
        mobile_number: "",
        ip_address: ""
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userLoading) return;

                if (!user) {
                    toast.error("Please login to continue");
                    return;
                }

                const affiliateResponse = await axios.get(`${API_URL}/api/affiliate/get_affiliate_details`, {
                    params: { email_id: user.email_id },
                    withCredentials: true
                });

                if (affiliateResponse.data.exists) {
                    setAffiliateDetails(affiliateResponse.data.data);
                    setLoading(false);
                    return;
                }

                const creatorResponse = await axios.get(`${API_URL}/api/creatorreg/find_by_email`, {
                    params: { email_id: user.email_id },
                    withCredentials: true
                });

                const ipResponse = await axios.get(`${API_URL}/api/ip`, {
                    withCredentials: true
                });

                setFormData(prev => ({
                    ...prev,
                    firstName: creatorResponse.data.name || "",
                    email_id: creatorResponse.data.email_id || "",
                    mobile_number: creatorResponse.data.mobile_number || "",
                    ip_address: ipResponse.data.ip || ""
                }));
            } catch (error) {
                console.error("Error generating affiliate ID:", error);
                toast.error(error.response?.data?.error || "Failed to generate affiliate ID");
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [user, userLoading]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isChecked) {
            toast.warning("Please accept the Terms & Conditions to continue");
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(`${API_URL}/api/affiliate/generate_affiliate_id`, formData,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            setAffiliateDetails(response.data.data);
            toast.success("Affiliate ID generated successfully");
        } catch (error) {
            console.error("Error generating affiliate ID:", error);
            toast.error("Failed to generate affiliate ID");
        } finally {
            setLoading(false);
        }
    }

    if (loading || userLoading) {
        return <PuffLoader color="#ffffff" size={24} />;
    }

    return (
        <div className="min-h-full bg-indigo-100 flex items-center justify-center py-4 md:py-7 px-3 md:px-6">
            <ToastContainer autoClose={2500} />
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                {affiliateDetails ? (
                    <div className="text-center p-6 bg-gradient-to-r from-indigo-50 to-blue-50 rounded-lg shadow-sm">
                        <h2 className="text-2xl md:text-3xl font-bold text-center text-indigo-600 mb-8">
                            Your Affiliate Details
                        </h2>

                        <div className="space-y-4">
                            <div className="bg-white p-4 rounded-lg shadow-sm border border-indigo-100">
                                <p className="text-sm text-indigo-600 font-semibold mb-2">Affiliate ID</p>
                                <p className="text-lg font-mono bg-gray-50 p-2 rounded select-all">
                                    {affiliateDetails.affiliate_id}
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <h1 className="text-2xl md:text-3xl font-bold text-center text-indigo-600 mb-6">
                            Generate your Affiliate ID for Affiliate Campaigns
                        </h1>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Name</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    readOnly
                                    className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500" />
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    type="email"
                                    name="email_id"
                                    value={formData.email_id}
                                    readOnly
                                    className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500" />
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Mobile Number</label>
                                <input
                                    type="text"
                                    name="mobile_number"
                                    value={formData.mobile_number}
                                    className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500" />
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">IP Address</label>
                                <input type="text"
                                    name="ip_address"
                                    value={formData.ip_address}
                                    readOnly
                                    className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500" />
                            </div>

                            <div className="flex items-center gap-2 mb-3">
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                    className="w-4 h-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label className="text-sm text-gray-600">
                                    I accept the Affiliate{" "}
                                    <a
                                        href="/docs/Affiliate_Terms_and_Conditions.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-indigo-600 hover:text-indigo-800 underline"
                                    >
                                        Terms & Conditions
                                    </a>{" "}
                                </label>
                            </div>

                            <button
                                type="submit"
                                // disabled={!isChecked}
                                className="w-full bg-indigo-600 text-white font-bold py-2 rounded-md hover:bg-indigo-700 transition duration-200"
                            >
                                Get Affiliate ID
                            </button>
                        </form>
                    </>
                )}

            </div>
        </div>
    );
}

export default GenerateAffiliate;