import React from "react";

export default function PrivacyPolicy() {
    return (
        <div className="container mx-auto p-4">
            <div>
                <h2 className="uppercase text-2xl font-bold mb-2">Privacy Policy</h2>
                <p>Effective date: 01th April 2024</p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">1. Introduction</h3>
                <p className="pl-6 py-1">Welcome to Bookmesocial.com,</p>
                <p className="pl-6 py-1">
                    <a href="https://bookmesocial.com/">Bookmesocial.com</a> (“us”, “we”, or “our”) operates <a href="https://bookmesocial.com/">https://bookmesocial.com/</a> (referred to as “Service”)
                    & other related companies/websites
                </p>
                <p className="pl-6 py-1">
                    This Privacy Policy governs your visit to <a href="https://bookmesocial.com/">https://bookmesocial.com/</a> & other related companies/websites,
                    and explains how we collect, safeguard and disclose information that results from your use of our
                    Service.
                </p>
                <p className="pl-6 py-1">
                    We use your data to provide and enhance the Service. By using the Service, you agree to the collection
                    and use of informationin accordance with this policy. Unless otherwise defined in this Privacy Policy, the
                    terms used in this Privacy Policy have the same meanings as in our Terms and Conditions. Our Terms
                    and Conditions (“Terms”) govern all use of our Service and together with the Privacy Policy constitutes
                    your agreement with us (“agreement”).
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">2. Definitions</h3>
                <p className="pl-6 py-1">
                    <span className="uppercase font-bold">Service</span> means the website <a href="https://bookmesocial.com/">https://bookmesocial.com/</a> & other affiliated companies/websites
                    operated by <a href="https://bookmesocial.com/">Bookmesocial.com</a>
                </p>
                <p className="pl-6 py-1">
                    <span className="uppercase font-bold">Personal Data</span> means data about a living individual who can be identified from that data (or
                    from those and other information either in our possession or likely to come into our possession).
                </p>
                <p className="pl-6 py-1">
                    <span className="uppercase font-bold">Usage Data</span> is data collected automatically either generated by the use of Service or from Service
                    infrastructure itself (for example, the duration of a page visit).
                </p>
                <p className="pl-6 py-1">
                    <span className="uppercase font-bold">Cookies</span> are small files stored on your device (computer or mobile device).
                </p>
                <p className="pl-6 py-1">
                    <span className="uppercase font-bold">Data Controller</span> means a natural or legal person who (either alone or jointly or in common
                    with other persons) determines the purposes for which and the manner in which any personal data are, or
                    are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.
                </p>
                <p className="pl-6 py-1">
                    <span className="uppercase font-bold">Data Processors (Or Service Provders)</span> means any natural or legal person who
                    processes the data on behalf of the Data Controller. We may use the services of various Service Providers
                    in order to process your data more effectively.
                </p>
                <p className="pl-6 py-1">
                    <span className="uppercase font-bold">Data Subject</span> is any living individual who is the subject of Personal Data.
                </p>
                <p className="pl-6 py-1">
                    <span className="uppercase font-bold">The User</span> is the individual using our Service. The User corresponds to the Data Subject, who is the
                    subject of Personal Data.
                </p>
                <p className="pl-6 py-1">
                    <span className="uppercase font-bold">Api Data</span> is the data that we collect from social media API when you give us access to that particular
                    information.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">3. Information Collection and Use</h3>
                <p className="pl-6 py-1">
                    We collect various types of information for different purposes to provide and enhance our Service to you.
                    Additionally, we aim to offer you the best brand collaborations by analyzing specific data points that
                    brands typically seek, including but not limited to follower range, engagement rate, average likes,
                    comments, tonality, interests, follower demographic, age ranges, audience overlap, and categories.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">4. Types of Data Collected</h3>
                <h3 className="text-2xl font-semibold py-4">Personal Data</h3>
                <p className="pl-6 py-1">
                    While using our Service, we may request certain personally identifiable information that can be used to
                    contact or identify you (“Personal Data”). This may include, but is not limited to:

                    <ul className="pl-4 list-disc py-1">
                        <li>Email address</li>
                        <li>First name and last name</li>
                        <li>Phone number</li>
                    </ul>
                </p>
                <p className="pl-6 py-1">
                    We may use your Personal Data to communicate with you via newsletters, marketing or promotional
                    materials, and other information that may be of interest to you. You can opt out of receiving any or all of
                    these communications by following the unsubscribe link.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">5. Usage Data</h3>
                <p className="pl-6 py-1">
                    We may also collect information sent by your browser whenever you visit or access our Service through
                    any device (“Usage Data”). This data may include, but is not limited to:

                    <ul className="pl-4 list-disc py-1">
                        <li>Internet Protocol address (IP address)</li>
                        <li>Browser type and version</li>
                        <li>Pages of our Service visited</li>
                        <li>Time and date of visit</li>
                        <li>Time spent on pages</li>
                        <li>Unique device identifiers</li>
                        <li>Other diagnostic data</li>
                    </ul>
                </p>
                <p className="pl-6 py-1">
                    When accessing our Service with a device, Usage Data may also include information such as the type of
                    device, device unique ID, device IP address, device operating system, type of Internet browser, and other
                    diagnostic data.
                </p>
                <p className="pl-6 py-1">
                    When accessing our Service with a device, Usage Data may include information such as the type of
                    device, unique device ID, device IP address, device operating system, type of Internet browser, and other
                    diagnostic data.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">6. Location Data</h3>
                <p className="pl-6 py-1">
                    If you grant us permission, we may use and store information about your location (“Location Data”) to
                    provide features of our Service and to enhance and customize it. You can enable or disable location
                    services at any time through your device settings.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">7. Tracking Cookies Data</h3>
                <p className="pl-6 py-1">
                    We use cookies and similar tracking technologies to monitor activity on our Service and hold certain
                    information. Cookies are small files containing data, including an anonymous unique identifier, sent to
                    your browser from a website and stored on your device. Other tracking technologies such as beacons,
                    tags, and scripts are also used to collect and track information and to improve and analyze our Service.
                </p>
                <p className="pl-6 py-1">
                    You can configure your browser to refuse all cookies or to indicate when a cookie is being sent.
                    However, if you decline cookies, you may not be able to use some portions of our Service.
                </p>
                <p className="pl-6 py-1">
                    Examples of Cookies we use include:

                    <ul className="pl-4 list-disc py-1">
                        <li>Session Cookies: Used to operate our Service</li>
                        <li>Preference Cookies: Used to remember your preferences and settings</li>
                        <li>Security Cookies: Used for security purposes</li>
                        <li>Advertising Cookies: Used to serve advertisements relevant to you and your interests</li>
                    </ul>
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">8. Data</h3>
                <p className="pl-6 py-1">
                    While using our Service, we may also collect additional information, including but not limited to sex, age, date of birth, place of birth, social media API data, place of residence and actual address, telephone number <span className="font-bold">(work, mobile)</span>, information on marital status, family members, social security <span className="font-bold">(or other taxpayer identification)</span> number, office location, and other data.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">
                    9. Data Subject Access Requests (DSARs)
                </h3>
                <p className="pl-6 py-1">
                    You have the right to access the personal information we hold about you. To make a DSAR, please contact us via email at {' '}
                    <a href="mailto:admin@bookmesocial.com" target="_blank" className="underline">
                        admin@bookmesocial.com
                    </a> & {' '}
                    <a href="mailto:bookmesocial@gmail.com" target="_blank" className="underline">
                        bookmesocial@gmail.com.
                    </a>
                </p>
                <p className="pl-6 py-1">
                    Please include 'DSAR' in the subject line of your email or on the envelope to ensure prompt handling of your request. We may require additional information to verify your identity before fulfilling the DSAR. We have the capability to delete, modify, or alter the Personal Data or Information based on your specific request.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">10. Security of Data</h3>
                <p className="pl-6 py-1">
                    We prioritize the security of your data. However, it is essential to understand that no method of transmission over the Internet or electronic storage is completely secure. While we strive to use
                    commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">
                    11. Your Data Protection Rights Under General Data Protection
                    Regulation (GDPR)
                </h3>
                <p className="pl-6 py-1">
                    If you are a resident of the European Union (EU) or European Economic Area (EEA), you have certain data protection rights under the GDPR:

                    <ul className="pl-4 list-disc py-1">
                        <li>The right to request deletion of the information we hold about you;</li>
                        <li>The right of rectification: you can have inaccurate or incomplete information corrected;</li>
                    </ul>
                </p>
                <p className="pl-6 py-1">
                    To process such requests, we may need to verify your identity. Please note that we may be unable to provide certain services without the necessary data. Additionally, you have the right to lodge a complaint with a Data Protection Authority regarding our handling of your Personal Data. For further information,
                    please contact your local data protection authority in the EEA.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">12. Service Providers</h3>
                <p className="pl-6 py-1">
                    We may engage third-party companies and individuals to support our Service <span className="font-bold">("Service Providers")</span>. These third parties assist us by providing services on our behalf, performing service-related tasks, or helping us analyze how our Service is used. These Service Providers have access to your Personal Data only to perform these tasks on our behalf and are contractually obligated not to disclose or use it for any other purpose.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">13. Analytics</h3>
                <p className="pl-6 py-1">
                    We may employ third-party Service Providers to monitor and analyze the usage of our Service. These analytics providers help us understand how users interact with our Service, enabling us to improve our offerings.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">14. CI/CD Tools</h3>
                <p className="pl-6 py-1">
                    We may use third-party Service Providers to automate various aspects of the development and deployment process of our Service. These tools help streamline our continuous integration and
                    continuous deployment (CI/CD) workflows, ensuring efficient and reliable updates.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">15. Behavioural Remarketing</h3>
                <p className="pl-6 py-1">
                    We may use remarketing services to advertise on third-party websites after you have visited our Service. Both we and our third-party vendors may use cookies to inform, optimize, and serve ads based on your past visits to our Service. This allows us to provide you with relevant advertisements that are more likely
                    to interest you.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">16. Payments</h3>
                <p className="pl-6 py-1">
                    If we offer paid products and/or services within our Service, we may utilize third-party payment processing services (e.g., payment processors). We do not store or collect your payment card details. This information is directly provided to our third-party payment processors, whose use of your personal information is governed by their Privacy Policy and their compliance with PCI-DSS requirements to ensure the secure handling of payment information.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">17. Links to Other Sites</h3>
                <p className="pl-6 py-1">
                    Our Service may contain links to external sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly recommend reviewing the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy
                    policies, or practices of any third-party sites or services.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">18. Your Data Rights</h3>
                <p className="pl-6 py-1">
                    At [Company Name], we adhere to the principles of "lawfulness, fairness, and transparency," and "accuracy," as outlined in Article 5(1)(a), (d) of the GDPR. We are committed to respecting your rights
                    under the General Data Protection Regulation, ensuring transparency and accountability in our data processing practices.
                </p>
                <h3 className="text-lg font-semibold py-4">Your Rights under GDPR</h3>
                <ul className="pl-4 list-disc py-1">
                    <li>
                        Access: Request access to your personal data (Art. 15 GDPR).
                    </li>
                    <li>
                        Rectification: Request rectification of your personal data (Art. 16 GDPR).
                    </li>
                    <li>
                        Erasure: Request erasure of your personal data (Art. 17 GDPR).
                    </li>
                    <li>
                        Restriction: Request restriction of your personal data processing (Art. 18 GDPR).
                    </li>
                    <li>
                        Portability: Request portability of your personal data (Art. 20 GDPR).
                    </li>
                    <li>
                        Objection: Object to the processing of your personal data (Art. 21 GDPR).
                    </li>
                    <li>
                        Automated Processing: Object to automated processing of your personal data (Art. 22 GDPR).
                    </li>
                    <li>
                        Withdrawal of Consent: Withdraw your consent to processing of your personal data (Art. 7(3)
                        GDPR).
                    </li>
                    <li>
                        Lodge a Complaint: Lodge a complaint with your national supervisory authority (Art. 13(2)(d),
                        14(2)(e), 15(1)(f)).
                    </li>
                </ul>
                <h3 className="text-lg font-semibold py-4">Consent and Withdrawal</h3>
                <p className="pl-6 py-1">
                    We provide clear information when processing your personal data for additional purposes and respect your right to refuse consent. You can withdraw your consent at any time by contacting our Data Protection Officer at {' '}<a href="mailto:dpo@hypeauditor.com" target="_blank">dpo@hypeauditor.com</a>, although withdrawal does not affect prior processing.
                </p>
                <h3 className="text-lg font-semibold py-4">Right to Object and Complaint
                </h3>
                <p className="pl-6 py-1">
                    If your personal data is processed without your consent, you can request its cessation and removal from our mailing list by contacting our Data Protection Officer. Additionally, unresolved queries can be escalated to your local data protection authority.
                </p>
                <h3 className="text-lg font-semibold py-4">Influencer Rights</h3>
                <p className="pl-6 py-1">
                    Influencers have the right to request information about customers who received their personal
                    data. [Company Name] commits to providing this information promptly upon request.
                    Influencers also have the right to access and request the erasure of their personal data, with
                    prompt action taken within 72 hours.
                </p>
                <p className="pl-6 py-1">
                    At [Company Name], we prioritize your data rights and are committed to transparent and efficient processes to ensure compliance with GDPR regulations and the protection of your privacy.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">19. Changes to This Privacy Policy</h3>
                <p className="pl-6 py-1">
                    We may update our Privacy Policy periodically. We will notify you of any changes by posting the updated Privacy Policy on this page. Additionally, we will inform you via email and/or a prominent notice on our Service before the changes take effect. We encourage you to review this Privacy Policy periodically to stay informed about any updates. Changes become effective upon being posted on this page.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">20. Behavioural Remarketing
                </h3>
                <h3 className="py-4 pl-6">Our Commitment to You</h3>
                <ul className="pl-10 list-disc py-1">
                    <li className="font-bold">
                        Legal Integrity: We collect and use your data only when we have a legal basis to do so.
                    </li>
                    <li className="font-bold">
                        Transparency: We pledge to always be clear and open about how we use your information.
                    </li>
                    <li className="font-bold">
                        Purpose-Driven Usage: Your data will only be used for the specific purpose it was collected, unless we obtain your consent or another legal basis applies.
                    </li>
                    <li className="font-bold">
                        Minimal Data Collection: We will never ask for more information than is necessary to provide our services.
                    </li>
                    <li className="font-bold">
                        Responsible Retention: We adhere to strict data retention policies, ensuring your information is securely disposed of once it is no longer needed.
                    </li>
                    <li className="font-bold">
                        Respecting Your Rights: We are committed to addressing privacy-related inquiries promptly and transparently.
                    </li>
                    <li className="font-bold">
                        Staff Training: Our team is continuously trained in privacy and security obligations to safeguard your data.
                    </li>
                    <li className="font-bold">
                        Robust Protection: We implement appropriate technological and organizational measures to protect your data, no matter where it is stored.
                    </li>
                    <li className="font-bold">
                        Secure Partnerships: All our data processors and subcontractors are required to have strong security measures and contractual obligations that align with our commitment to your privacy.
                    </li>
                </ul>
                <p className="pl-6 py-1">
                    Your trust is paramount to us, and we strive to uphold these principles in every aspect of our data handling practices
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">21. Use of Public Data</h3>
                <p className="pl-6 py-1">
                    The platform may use publicly available data to process and analyze information about users who have chosen to make their profiles or content public on social media. This may include names, usernames, bios, public posts, and other data you have chosen to make public. We may also partner with other companies to acquire additional public data.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">22. Collecting Your Personal Data</h3>
                <p className="pl-6 py-1">
                    We collect information about you in the following ways:{' '}
                    <span className="font-bold">Information You Give Us</span>. This includes:
                </p>
                <ul className="pl-10 list-disc py-1">
                    <li>
                        the personal data you provide when you register to use our website, including your name, postal address, email address, telephone number, username, password and demographic information (such as your gender);
                    </li>
                    <li>
                        the personal data that may be contained in any video, comment or other submission you upload or post to the website;
                    </li>
                    <li>
                        the personal data you provide in connection with our rewards program and other promotions we run on the website;
                    </li>
                    <li>
                        the personal data you provide when you report a problem with our website or when we provide you with customer support;
                    </li>
                    <li>
                        the personal data you provide when you make a purchase thorough our website; and
                    </li>
                    <li>
                        the personal data you provide when you correspond with us by phone, email or otherwise.
                    </li>
                </ul>
                <h3 className="text-lg font-semibold py-4">Information from Social Networking Websites.</h3>
                <p className="pl-6 py-1">
                    Our website includes interfaces that allow you to connect with social networking sites (each a “SNS”). If you connect to a SNS through our website, you authorize us to access, use and store the
                    information that you agreed the SNS could provide to us based on your settings on that SNS. We will access, use and store that information in accordance with this Policy. You can revoke our
                    access to the information you provide in this way at any time by amending the appropriate settings from within your account settings on the applicable SNS.
                </p>
                <h3 className="text-lg font-semibold py-4">Information Automatically Collected.</h3>
                <p className="pl-6 py-1">
                    We automatically log information about you and your computer or mobile device when you access our website. For example, when visiting our website, we log your computer or mobile device
                    operating system name and version, manufacturer and model, browser type, browser language, screen resolution, the website you visited before browsing to our website, pages you viewed, how
                    long you spent on a page, access times and information about your use of and actions on our website. We collect this information about you using cookies.
                </p>
                <h3 className="text-lg font-semibold py-4">Automated Decision Making and Profiling.</h3>
                <p className="pl-6 py-1">
                    We do not use your personal data for the purposes of automated decision-making. However, we may do so in order to ful fill obligations imposed by law, in which case we will inform you of any
                    such processing and provide you with an opportunity to object.
                </p>
                <h3 className="text-lg font-semibold py-4">User Profiles and Submissions.</h3>
                <p className="pl-6 py-1">
                    Certain user profile information, including your name, location, and any video or image content that such user has uploaded to the Services, may be displayed to other users to facilitate user interaction within the Services or address your request for our services. Your account privacy settings may allow you to limit the other users who can see the Personal Information in your user profile and/or what information in your user profile is visible to others. Please remember that any content you upload to your public user profile, along with any Personal Information or content that you voluntarily disclose online in a manner other users can view (on discussion boards, in messages and chat areas, etc.) becomes publicly available, and can be collected and used by anyone. Your user name may also be displayed to other users if and when you send messages or comments or upload images or videos through the Services and other users can contact you through messages and
                    comments. Additionally, if you sign into the Services through a third party social networking site or service, your list of “friends” from that site or service may be automatically imported to the Services, and such “friends,” if they are also registered users of the Services, may be able to access certain non-public information you have entered in your Services user profile. Again, we do not control the policies and practices of any other third party site or service.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">23. Cookies</h3>
                <h3 className="text-lg font-semibold">What are cookies?</h3>
                <p className="pl-6 py-1">
                    We may collect information using “cookies.” Cookies are small data files stored on the hard drive of your computer or mobile device by a website. We may use both session cookies (which expire once
                    you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them) to provide you with a more personal and interactive experience on our
                    website.
                </p>
                <p className="pl-6 py-1">
                    We use two broad categories of cookies: (1) first party cookies, served directly by us to your computer or mobile device, which are used only by us to recognize your computer or mobile device when it revisits our website; and (2) third party cookies, which are served by service providers on our website, and can be used by such service providers to recognize your computer or mobile device when it visits other websites.
                </p>
                <h3 className="text-lg font-semibold py-2">Cookies we use</h3>
                <p className="pl-6">
                    Our website uses the following types of cookies for the purposes set out below:
                </p>
                <p className="pl-6 py-1">
                    <span className="font-bold">Essential Cookies -</span> These cookies are essential to provide you with services available through our website and to enable you to use some of its features. For example, they allow you to log in to secur
                    e areas of our website and help the content of the pages you request load quickly. Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide you with those services.
                </p>
                <p className="pl-6 py-1">
                    <span className="font-bold">Functionality Cookies -</span> These cookies allow our website to remember choices you make when you
                    use our website, such as remembering your language preferences, remembering your login details and remembering the changes you make to other parts of our website which you can customize. The
                    purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you visit our website.
                </p>
                <p className="pl-6 py-1">
                    <span className="font-bold">Analytics and Performance Cookies -</span> These cookies are used to collect information about traffic t
                    o our website and how users use our website. The information gathered does not identify any individual visitor. It includes the number of visitors to our website, the websites that referred them to our website, the pages they visited on our website, what time of day they visited our website, whether they have visited our website before, and other similar information. We use this information to help operate our website more efficiently, to gather broad demographic information and to monitor the level of activity on our website. We use Google Analytics for this purpose. Google Analytics uses its own cookies. It is only used to improve how our website works. You can find out more information about Google Analytics cookies here: <a href="https://developers.google.com/analytics/resources/concepts/ga" target="_blank">https://developers.google.com/analytics/resources/concepts/ga</a> ConceptsCookies You can find out more about how Google protects your data here: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>. You can prevent the use of Google Analytics relating to your use of our website by downloading and installing the browser plugin available via this link: <a href="http://tools.google.com/dlpage/gaoptout?hl=en-GB">http://tools.google.com/dlpage/gaoptout?hl=en-GB</a>
                </p>
                <p className="pl-6 py-1">
                    <span className="font-bold">Targeted and advertising cookies -</span> These cookies track your browsing habits to enable us to show
                    advertising which is more likely to be of interest to you. These cookies use information about your browsing history to group you with other users who have similar interests. Based on that information, and with our permission, third party advertisers can place cookies to enable them to show adverts which we think will be relevant to your interests while you are on third party websites. You can disable cookies which remember your browsing habits and target advertising at you by visiting <a href="http://www.youronlinechoices.com/uk/your-ad-choices" target="_blank">http://www.youronlinechoices.com/uk/your-ad-choices</a>. If you choose to remove targeted or advertising cookies, you will still see adverts but they may not be relevant to you. Even if you do choose to remove cookies by the companies listed at the above link, not all companies that serve online behavioral advertising are included in this list, and so you may still receive some cookies and tailored adverts from companies that are not listed.
                </p>
                <p className="pl-6 py-1">
                    <span className="font-bold">Social Media Cookies -</span> These cookies are used when you share information using a social media sharing button or “like” button on our website or you link your account or engage with our content on or through a social networking website such as Facebook, Twitter or Google+. The social network will record that you have done this.
                </p>
                <h3 className="text-lg font-semibold py-4">Disabling cookies</h3>
                <p className="pl-6 py-1">
                    You can typically remove or reject cookies via your browser settings. In order to do this, follow the instructions provided by your browser (usually located within the “settings,” “help” “tools” or “edit” facility). Many browsers are set to accept cookies until you change your settings.
                </p>
                <p className="pl-6 py-1">
                    If you do not accept our cookies, you may experience some inconvenience in your use of our website. For example, we may not be able to recognize your computer or mobile device and you may need to log in every time you visit our website.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">24. We use this data to:</h3>
                <ul className="list-disc pl-10 py-3">
                    <li>
                        Improve our services: By analyzing public data, we can better understand our user base and tailor our services to their needs.
                    </li>
                    <li>
                        Targeted advertising: We may use public data to create anonymized or aggregated profiles to deliver more relevant advertising.
                    </li>
                </ul>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">25. Social Media Integration</h3>
                <p className="pl-6 py-1">
                    We offer features that allow you to connect your social media accounts to enhance your experience on our platform. Please review this section to understand how we handle data related to social media integration.
                </p>
                <p className="pl-6 py-1">
                    This Platform collects all reports, Metric's,data's,Communications used to make creators economy more valuable and users, advertisers, profile owners create monetization for social all media users and etc*.
                </p>
                <h3 className="text-lg font-semibold py-2">What data we collect</h3>
                <p className="pl-6">
                    When you connect your social media account (e.g., Instagram, Facebook, Youtube), we may collect the following information:
                </p>
                <ul className="list-disc pl-10 py-3">
                    <li>
                        Public profile information (e.g., name, profile picture, username, bio).
                    </li>
                    <li>
                        Social media-specific metrics (e.g., follower count, engagement statistics).
                    </li>
                    <li>
                        Posts or content that you grant us permission to access.
                    </li>
                </ul>

                <h3 className="text-lg font-semibold py-2">How we use your social media data</h3>
                <p className="pl-6 py-1">
                    The data collected from social media platforms is used for:
                </p>
                <ul className="list-disc pl-10 py-3">
                    <li>
                        Personalizing your experience on our platform.
                    </li>
                    <li>
                        Displaying relevant information about your connected social accounts.
                    </li>
                </ul>

                <h3 className="text-lg font-semibold py-2">Third-party policies</h3>
                <p className="pl-6 py-1">
                    Your interaction with social media platforms is subject to the respective platform's privacy policies. We recommend reviewing their terms to understand their data handling practices.
                </p>

                <h3 className="text-lg font-semibold py-2">Security and Permissions</h3>
                <p className="pl-6 py-1">
                    We ensure secure transmission of data when connecting with third-party platforms. Permissions are limited to the scope you approve during the integration process.
                </p>
            </div>

            <div className="py-4">
                <h3 className="text-2xl font-semibold">26. Updates to Social Media Integration Policy</h3>
                <p className="pl-6 py-1">
                    This policy may be updated as we integrate additional features or collaborate with new social media platforms. Any significant changes will be communicated to you through our platform.
                </p>
            </div>

            <div className="py-3">
                <h3 className="text-2xl font-semibold">27. Contact Us</h3>
                <p className="pl-6 py-1">
                    If you have any questions about this Privacy Policy, please contact us via email <a href="mailto:admin@bookmesocial.com" target="_blank" rel="noreferrer" className="underline">admin@bookmesocial.com</a>.
                </p>
                <p className="pl-6 py-1">
                    These file terms/Conditions will change as per bookmesocial.com policy changes.
                </p>
                <p className="pl-6 py-1">
                    Daily basis. Kindly contact our team for current Terms & Policy for Parties.
                </p>
                <p className="pl-6 py-1">
                    This detailed and comprehensive version of the Privacy Policy aims to provide clarity and assurance regarding our practices and your rights concerning your personal data.
                </p>
            </div>

        </div>
    );
}