import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Outlet, NavLink, Link } from "react-router-dom";
import logo from "../assets/logo (53).png";
import { ReactComponent as Instagram } from "../assets/svg/instagram.svg";
import { FaBars, FaTimes, FaHome, FaUser, FaMoneyBill, FaHeadset, FaCog, FaBell, FaChevronDown, FaChevronUp, FaUserCircle, FaYoutube, FaPlus, FaCreditCard, FaLock, FaTag, FaCheck } from "react-icons/fa";
import { API_URL } from "../constant/constant";
import { toast } from "react-toastify";
import { UserContext } from "../Pages/Auth/UserContext";

const DashboardLayout = () => {
    const { user, loading } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
    const [isEarningsOpen, setIsEarningsOpen] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMessagePanelOpen, setIsMessagePanelOpen] = useState(false);
    const [affiliateDetails, setAffiliateDetails] = useState(null);
    const [messages, setMessages] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [isConnected, setIsConnected] = useState(false);
    const [ytConnected, setYtConnected] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const isFirstVisit = !localStorage.getItem('hasVisited');
        if (isFirstVisit) {
            setIsModalOpen(true);
            localStorage.setItem('hasVisited', true);
        }

        handleCallback();
        fetchData();
        fetchIGProfile();
        fetchMessages();

        const interval = setInterval(fetchMessages, 10000);
        return () => clearInterval(interval);
    }, [user]);

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const handleConnect = () => {
        if (isConnected) {
            toast.info("Your Instagram account is already connected.");
            return;
        }
        window.location.href = `${API_URL}/api/instagram/auth/instagram`;
    };

    const fetchIGProfile = async () => {
        if (loading) return;

        try {
            const email_id = user.email_id;

            if (!email_id) {
                toast.error("Email ID not found. Please log in again.");
                return;
            }

            const response = await axios.get(`${API_URL}/api/instagram/get_profile_by_email`, {
                params: { email_id },
                withCredentials: true,
            });

            if (response.data.message === "Instagram Already Connected") {
                setIsConnected(true);
                // toast.success(response.data.message);
            } else {
                // toast.info("Instagram is not connected.");
                setIsConnected(false);
            }
        } catch (error) {
            console.error("Failed to fetch profile", error);
        }
    }

    const fetchProfile = async () => {
        if (loading) return;

        try {
            const access_token = Cookies.get('ig_access_token');
            const user_id = Cookies.get('user_id');

            if (!access_token || !user_id) {
                toast.error('No authentication data found in cookies');
                return;
            }
            const email_id = user.email_id;

            const response = await axios.get(`${API_URL}/api/instagram/profile`, {
                withCredentials: true,
                params: { access_token, user_id, email_id },
            });

            // toast.success(response.data.message);
        } catch (err) {
            console.error("Failed to fetch profile", err);
            toast.error('Failed to fetch profile data');
        }
    };

    const handleCallback = async () => {
        if (window.location.href.includes('#_')) {
            const cleanUrl = window.location.href.split('#_')[0];
            window.history.replaceState(null, '', cleanUrl);
        }

        const params = new URLSearchParams(window.location.search);
        const code = params.get("code");

        if (code) {
            const cleanUrl = window.location.origin + window.location.pathname;
            window.history.replaceState(null, '', cleanUrl);
            try {
                const response = await axios.get(`${API_URL}/api/instagram/auth/instagram/callback`, {
                    withCredentials: true,
                    params: { code },
                });

                const { access_token, user_id } = response.data;

                if (access_token && user_id) {
                    Cookies.set("ig_access_token", access_token);
                    Cookies.set("user_id", user_id);
                    setIsConnected(true);
                    await fetchProfile();
                    //   await fetchAnalytics();

                    // window.location.href = "https://www.bookmesocial.com/CP/dashboard";
                } else {
                    throw new Error("Missing access token or user ID");
                }
            } catch (err) {
                console.error("Failed to handle callback", err);
                toast.error("Failed to connect Instagram.");
            }
        } else {
            const access_token = Cookies.get('ig_access_token');
            const user_id = Cookies.get('user_id');

            if (access_token && user_id) {
                setIsAuthenticated(true);
                await fetchProfile();
                // await fetchAnalytics();
            }
        }
    };

    const fetchMessages = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/messages/creator-messages/all`);
            setMessages(response.data.messages);
            setUnreadCount(response.data.unreadCount);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const fetchData = async () => {
        try {
            if (loading) return;

            if (!user) {
                toast.error("Please login to continue");
                return;
            }
            const affiliateResponse = await axios.get(`${API_URL}/api/affiliate/get_affiliate_details`, {
                params: { email_id: user.email_id },
                withCredentials: true
            });

            const ytResponse = await axios.get(`${API_URL}/api/creatorreg/find_by_email`, {
                params: { email_id: user.email_id },
                withCredentials: true
            });

            if (affiliateResponse.data.exists) {
                setAffiliateDetails(affiliateResponse.data.data);
                // return;
            }

            if (ytResponse.status === 200) {
                setYtConnected(ytResponse.data.connected);
            }
        } catch (error) {
            console.error("Error fetching affiliate details:", error);
        }
    }

    const markMessageAsRead = async (messageId) => {
        try {
            const response = await axios.put(`${API_URL}/api/messages/mark-read/${messageId}`);
            if (response.data.success) {
                setMessages(response.data.messages);
                setUnreadCount(response.data.unreadCount);
            }
        } catch (error) {
            console.error('Error marking message as read:', error);
        }
    };

    const handleMessageButtonClick = async () => {
        try {
            // Mark all unread messages as read
            const unreadMessages = messages.filter(msg => !msg.isRead);
            for (const msg of unreadMessages) {
                await markMessageAsRead(msg._id);
            }

            setIsMessagePanelOpen(true);
        } catch (error) {
            console.error('Error marking messages as read:', error);
        }
    };

    const handleLogout = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/creatorreg/creator_logout`, {
                withCredentials: true
            });

            const domains = [
                '.bookmesocial.com',
                'bookmesocial.com',
                'localhost',
                window.location.hostname
            ];

            domains.forEach(domain => {
                document.cookie = `creator_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}; secure; samesite=none`;
            });

            localStorage.removeItem('hasVisited');

            toast.success(response.data.message, {
                toastId: 'logout-success'
            });

            await new Promise(resolve => setTimeout(resolve, 500));

            window.location.href = '/CP/brandlogin';
        } catch (error) {
            console.error('Logout error:', error);
            toast.error(error.response?.data?.message || "Logout failed", {
                toastId: 'logout-error'
            });
        }
    };

    const handleContactAdmin = (isMobile = false) => {
        const adminEmail = 'admin@bookmesocial.com';
        const subject = 'Creator Support Request';
        const body = 'Hello Admin,\n\nI need assistance with...';

        if (isMobile) {
            const mailtoLink = `mailto:${adminEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.location.href = mailtoLink;
        } else {
            const gmailComposeUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${adminEmail}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.open(gmailComposeUrl, '_blank');
        }
    };

    const MessageButton = () => (
        <button
            onClick={handleMessageButtonClick}
            className="relative bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
            Message
            {unreadCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-blue-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {unreadCount}
                </span>
            )}
        </button>
    );

    return (
        <div className="flex h-dvh bg-gray-300 p-2">
            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] max-w-md max-h-[95vh] overflow-y-auto relative md:my-0">
                        <button
                            onClick={closeModal}
                            className="absolute top-2 right-3 text-xl bg-red-500 text-white rounded w-7 h-7 flex items-center justify-center hover:bg-red-600 transition"
                        >
                            &times;
                        </button>
                        <h2 className="text-2xl text-center font-bold mb-4 text-gray-800 mt-4 md:mt-0">
                            Platform Update & Notification
                        </h2>
                        <p className="mb-3 text-gray-600">Greetings,</p>
                        <p className="mb-3 text-gray-600">
                            This platform is currently under development.
                        </p>
                        <h2 className="text-lg font-semibold mb-3 text-gray-700">Mode: Pilot Model</h2>
                        <p className="mb-3 text-gray-600">
                            Kindly provide your channel details and set up your profile on the Account page.
                        </p>
                        <p className="mb-3 text-gray-600">
                            In the meantime, we are conducting paid brand collaborations with selected profiles via email confirmation.
                        </p>
                        <p className="mb-3 text-gray-600">
                            If you believe your channel can make an impact on brand campaigns, kindly write to us at {''}
                            <a
                                href="mailto:admin@bookmesocial.com"
                                className="relative inline-block font-medium tracking-wide text-indigo-600 hover:text-indigo-700 transition ease-in-out duration-300"
                            >
                                admin@bookmesocial.com
                            </a>.
                        </p>
                        <p className="mb-3 text-gray-600">
                            We apologize for any inconvenience at this time.
                        </p>
                    </div>
                </div>
            )}

            {/* Mobile Header */}
            <div className="md:hidden fixed top-0 left-0 right-0 bg-white shadow-md p-4 flex justify-between items-center z-50">
                <img className="h-8 w-auto" src={logo} alt="Your Company" />

                <div className="flex justify-center items-center gap-4">
                    <Link to="/CP/dashboard/digital-store">
                        <button className="bg-red-500 hover:bg-red-700 text-white font-bold text-[0.7rem] py-2 px-4 rounded relative">
                            My Digital Store
                        </button>
                    </Link>
                    <button
                        className="text-black"
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    >
                        {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </button>
                </div>
            </div>

            {/* Main Container */}
            <div className="flex w-full h-full max-w-full bg-white shadow-lg rounded-xl overflow-hidden m-auto">
                {/* Sidebar */}
                <div className={`
                    bg-indigo-600 
                    md:w-64 md:block
                    ${isMobileMenuOpen ? 'absolute w-52 left-0 h-full' : 'hidden'}
                    md:relative transition-all duration-300 ease-in-out
                    z-40 md:z-0
                `}>
                    <div className="h-full p-5 overflow-y-auto">
                        {/* Sidebar Content */}
                        <div className="mb-6">
                            <img className="hidden md:block h-10 w-auto mx-auto" src={logo} alt="Your Company" />
                        </div>
                        <hr className="border-t border-indigo-400 mb-6" />
                        <ul>
                            <li className="mb-2 md:text-xl text-base">
                                <div className="bg-indigo-500 text-white p-3 rounded-md flex items-center justify-between">
                                    <span className="flex items-center">
                                        <FaPlus className="mr-2" />
                                        Add Social
                                    </span>
                                </div>

                                <hr className="my-4 border-t-2 border-gray-300" />

                                <div className="mt-4 flex gap-4">
                                    <div className="p-2 bg-white rounded-full flex flex-col items-center justify-center relative">
                                        <NavLink
                                            to="/CP/dashboard/social/youtube"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "flex items-center justify-center"
                                                    : "flex items-center justify-center transition-all duration-300"
                                            }
                                        >
                                            <FaYoutube className="text-3xl text-[#FF0000]" />
                                        </NavLink>
                                        {ytConnected && (
                                            <span className="absolute top-[-2px] right-[-2px] bg-green-500 text-white rounded-full p-1 text-[0.5rem] flex items-center justify-center">
                                                <FaCheck />
                                            </span>
                                        )}
                                    </div>

                                    <div className="p-2 bg-white rounded-full flex flex-col items-center justify-center relative">
                                        <button
                                            onClick={handleConnect}
                                            disabled={isConnected}
                                            className="flex items-center justify-center transition-all duration-300"
                                        >
                                            <Instagram className="size-[2rem]" />
                                            {isConnected && (
                                                <span className="absolute top-[-2px] right-[-2px] bg-green-500 text-white rounded-full p-1 text-[0.5rem] flex items-center justify-center">
                                                    <FaCheck />
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/CP/dashboard/home"
                                    className={({ isActive }) =>
                                        isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaHome className="mr-2" />Dashboard
                                </NavLink>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/CP/dashboard/offers"
                                    className={({ isActive }) =>
                                        isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaTag className="mr-2" />Offers
                                </NavLink>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <button
                                    className="flex items-center justify-between w-full text-left p-3 rounded-md text-white hover:bg-indigo-500"
                                    onClick={() => setIsAccountDropdownOpen(!isAccountDropdownOpen)}
                                >
                                    <span className="flex items-center">
                                        <FaUserCircle className="mr-2" />
                                        Account
                                    </span>
                                    {isAccountDropdownOpen ? <FaChevronUp className="text-sm" /> : <FaChevronDown className="text-sm" />}
                                </button>
                                <div
                                    className={`transition-all duration-500 overflow-hidden ${isAccountDropdownOpen ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                                        }`}
                                >
                                    <ul className="pl-6 mt-2 space-y-2">
                                        <li>
                                            <NavLink
                                                to="/CP/dashboard/profile"
                                                className={({ isActive }) =>
                                                    isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                                }
                                            >
                                                <FaUser className="mr-2" />
                                                Profile
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <button
                                    className="flex items-center justify-between w-full text-left p-3 rounded-md text-white hover:bg-indigo-500"
                                    onClick={() => setIsEarningsOpen(!isEarningsOpen)}
                                >
                                    <span className="flex items-center">
                                        <FaMoneyBill className="mr-2" />
                                        Earnings
                                    </span>
                                    {isEarningsOpen ? <FaChevronUp className="text-sm" /> : <FaChevronDown className="text-sm" />}
                                </button>
                                <div
                                    className={`transition-all duration-500 overflow-hidden ${isEarningsOpen ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                                        }`}
                                >
                                    <ul className="pl-6 mt-2 space-y-2">
                                        <li>
                                            <NavLink
                                                to="/CP/dashboard/earnings"
                                                className={({ isActive }) =>
                                                    isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                                }
                                            >
                                                <FaCreditCard className="mr-2" />
                                                Add your Bank
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/CP/dashboard/support"
                                    className={({ isActive }) =>
                                        isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaHeadset className="mr-2" />
                                    Support
                                </NavLink>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <button
                                    className="flex items-center justify-between w-full text-left p-3 rounded-md text-white hover:bg-indigo-500"
                                    onClick={() => setIsSettingsOpen(!isSettingsOpen)}
                                >
                                    <span className="flex items-center">
                                        <FaCog className="mr-2" />
                                        Settings
                                    </span>
                                    {isSettingsOpen ? <FaChevronUp className="text-sm" /> : <FaChevronDown className="text-sm" />}
                                </button>
                                <div
                                    className={`transition-all duration-500 overflow-hidden ${isSettingsOpen ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                                        }`}
                                >
                                    <ul className="pl-6 mt-2 space-y-2">
                                        <li>
                                            <NavLink
                                                to="/CP/dashboard/reset-password"
                                                className={({ isActive }) =>
                                                    isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                                }
                                            >
                                                <FaLock className="mr-2" />
                                                Reset Password
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/CP/dashboard/notifications"
                                    className={({ isActive }) =>
                                        isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white cursor-not-allowed" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white cursor-not-allowed"
                                    }
                                    onClick={(e) => e.preventDefault()}
                                    aria-disabled="true"
                                >
                                    <FaBell className="mr-2" />
                                    Notifications
                                </NavLink>
                            </li>
                        </ul>

                        {/* Mobile-only buttons */}
                        <div className="md:hidden mt-6 space-y-2">
                            <Link to="/CP/dashboard/generate-affiliate" className="block w-full">
                                <button className="bg-red-500 hover:bg-red-700 text-white text-[14px] py-1.5 px-3 rounded w-full relative">
                                    {affiliateDetails && (
                                        <span className="absolute top-[-2px] right-[-2px] bg-green-500 text-white rounded-full p-1 text-[0.5rem] flex items-center justify-center">
                                            <FaCheck />
                                        </span>
                                    )}
                                    Generate Affiliate ID
                                </button>
                            </Link>
                            <button
                                onClick={handleMessageButtonClick}
                                className="relative bg-red-500 hover:bg-red-700 text-white text-[14px] py-1.5 px-3 w-full rounded"
                            >
                                Message
                                {unreadCount > 0 && (
                                    <span className="absolute -top-2 -right-2 bg-blue-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                                        {unreadCount}
                                    </span>
                                )}
                            </button>
                            <button
                                onClick={() => handleContactAdmin(true)}
                                className="bg-red-500 hover:bg-red-700 text-white text-[14px] py-1.5 px-3 rounded w-full"
                            >
                                Contact Administrator
                            </button>
                            <Link to="/CP/dashboard/creator-faq" className="block w-full">
                                <button className="bg-red-500 hover:bg-red-700 text-white text-[14px] py-1.5 px-3 rounded w-full">
                                    Creator FAQ
                                </button>
                            </Link>
                            <button
                                onClick={handleLogout}
                                className="bg-red-500 hover:bg-red-700 text-white text-[14px] py-1.5 px-3 rounded w-full"
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </div>

                {/* Main Content Area */}
                <div className="flex-1 flex flex-col min-h-0 md:pl-0 pt-16 md:pt-0">
                    {/* Desktop Header */}
                    <header className="hidden md:flex w-full bg-indigo-700 text-white justify-between items-center p-4 mb-6 rounded-tr-md">
                        <div className="text-xl font-bold mr-2">Dashboard</div>
                        <div className="flex items-center space-x-4">
                            <Link to="/CP/dashboard/digital-store">
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded relative">
                                    My Digital Store
                                </button>
                            </Link>

                            <Link to="/CP/dashboard/generate-affiliate">
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded relative">
                                    {affiliateDetails && (
                                        <span className="absolute top-[-2px] right-[-2px] bg-green-500 text-white rounded-full p-1 text-[0.6rem] flex items-center justify-center">
                                            <FaCheck />
                                        </span>
                                    )}
                                    Generate Affiliate ID
                                </button>
                            </Link>

                            <MessageButton />

                            <button
                                onClick={() => handleContactAdmin(false)}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Contact Administrator
                            </button>

                            <Link to="/CP/dashboard/creator-faq">
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                    Creator FAQ
                                </button>
                            </Link>

                            <button
                                onClick={handleLogout}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Logout
                            </button>
                        </div>
                    </header>

                    {/* Main Content */}
                    <main className="flex-1 overflow-y-auto bg-white p-4 shadow rounded-md">
                        <Outlet />
                    </main>
                </div>
            </div>

            {/* Mobile Overlay */}
            {isMobileMenuOpen && (
                <div
                    className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-30"
                    onClick={() => setIsMobileMenuOpen(false)}
                />
            )}

            {isMessagePanelOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg w-full max-w-lg max-h-[90vh] flex flex-col">
                        {/* Header */}
                        <div className="flex justify-between items-center p-4 border-b">
                            <h2 className="text-xl font-bold text-gray-800">Messages</h2>
                            <button
                                onClick={() => setIsMessagePanelOpen(false)}
                                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                            >
                                <FaTimes />
                            </button>
                        </div>

                        {/* Messages Container */}
                        <div className="flex-1 overflow-y-auto p-4">
                            {messages.length === 0 ? (
                                <div className="flex items-center justify-center h-full">
                                    <p className="text-gray-500 text-center">No messages</p>
                                </div>
                            ) : (
                                <div className="space-y-4">
                                    {messages.map((msg) => (
                                        <div
                                            key={msg._id}
                                            className={`p-4 border rounded-lg shadow-sm
                                                ${!msg.isRead ? 'bg-blue-50 border-blue-100' : 'bg-white border-gray-200'}`}
                                            onClick={() => markMessageAsRead(msg._id)}
                                        >
                                            <div className="font-medium text-gray-800">{msg.subject}</div>
                                            <div className="text-gray-600 mt-2 text-sm whitespace-pre-wrap">
                                                {msg.message}
                                            </div>
                                            <div className="text-xs text-gray-400 mt-2 flex items-center">
                                                <span>{new Date(msg.createdAt).toLocaleDateString()}</span>
                                                <span className="mx-2">•</span>
                                                <span>
                                                    {new Date(msg.createdAt).toLocaleTimeString([], {
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DashboardLayout;