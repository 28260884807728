import {
  BrowserRouter as Router, Routes, Route, useLocation, Navigate, useNavigate
} from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./Pages/About";
import Contactus from "./Pages/Contactus";
import Brandlogin from "./Pages/Auth/Brandlogin";
import Brandregister from "./Pages/Auth/Brandregister";
import Creatorregister from "./Pages/Auth/Creatorregister";
import Support from "./Pages/Support";
import DashboardLayout from "./components/DashboardLayout";
import BrandDashboardLayout from "./components/BrandDashboardLayout";
import DashboardHome from "./Pages/Dashboard/DashboardHome";
import Profile from "./Pages/Dashboard/Profile";
import Settings from "./Pages/Dashboard/Settings";
import DashSupport from "./Pages/Dashboard/UserSupport";
import MyEarnings from "./Pages/Dashboard/MyEarnings";
import CreatorFAQ from "./Pages/Dashboard/CreatorFAQ";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import { UserProvider } from "./Pages/Auth/UserContext";
import axios from "axios";
import { useState, useEffect } from "react";
import { API_URL } from "./constant/constant";
import { PuffLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import YouTube from "./Pages/Dashboard/Youtube";
import Instagram from "./Pages/Dashboard/Instagram";
import GenerateAffiliate from "./Pages/Dashboard/GenerateAffiliate";
import OffersDashboard from "./Pages/Dashboard/OffersDashboard";

function AdminRedirect() {
  window.location.replace("https://admindashboard.bookmesocial.com");
  return null;
}

const useIsDashboard = () => {
  const { pathname } = useLocation();
  return pathname.startsWith("/CP/dashboard");
};

const useIsBrandDashboard = () => {
  const { pathname } = useLocation();
  return pathname.startsWith("/CP/brand-dashboard");
}

function ProtectedRoute({ children, role }) {
  const [isAuth, setIsAuth] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/auth/check`,
          {
            params: { role },
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.data.authenticated && response.data.role === role) {
          setIsAuth(true);
        } else {
          setIsAuth(false);
          navigate("/CP/brandlogin");
        }
      } catch (error) {
        console.error("Auth check error:", error);
        setIsAuth(false);
        navigate("/CP/brandlogin");
      }
    };
    checkAuth();
  }, [navigate, role]);

  if (isAuth === null) {
    return (
      <div className="loader-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <PuffLoader color="#4A90E2" size={100} />
      </div>
    );
  }
  return children;
};

function App() {
  useEffect(() => {
    toast.dismiss();
    return () => {
      toast.dismiss();
    };
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Content />
      </Router>
    </>
  );
}

function Content() {
  const isDashboard = useIsDashboard();
  const isBrandDashboard = useIsBrandDashboard();

  return (
    <>
      {!isDashboard && !isBrandDashboard && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="CP/about" element={<About />} />
        <Route path="CP/contactus" element={<Contactus />} />
        <Route path="CP/brandlogin" element={<Brandlogin />} />
        <Route path="CP/brandregister" element={<Brandregister />} />
        <Route path="CP/creatorregister" element={<Creatorregister />} />
        <Route path="CP/support" element={<Support />} />
        <Route path="CP/admin" element={<AdminRedirect />} />
        <Route path="CP/legal/privacy_policy" element={<PrivacyPolicy />} />
        {/* <Route path="/yolastarz" element={<Yolo />} /> */}
        <Route
          path="CP/forgot-password"
          element={
            // <UserProvider>
            <ForgotPassword />
            // </UserProvider>
          }
        />

        {/* Dashboard Routes */}
        <Route
          path="/CP/dashboard/*"
          element={
            <ProtectedRoute role="creator">
              <UserProvider>
                <DashboardLayout />
              </UserProvider>
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />

          <Route path="home" element={<DashboardHome />} />
          <Route path="offers" element={<OffersDashboard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="earnings" element={<MyEarnings />} />
          <Route path="support" element={<DashSupport />} />
          <Route path="reset-password" element={<Settings />} />
          <Route path="creator-faq" element={<CreatorFAQ />} />
          <Route path="generate-affiliate" element={<GenerateAffiliate />} />
          <Route path="social/youtube" element={<YouTube />} />
          <Route path="social/instagram" element={<Instagram />} />
        </Route>

        {/* Brand Dashboard Routes */}
        <Route
          path="/CP/brand-dashboard"
          element={
            <ProtectedRoute role="brand">
              <UserProvider>
                <BrandDashboardLayout />
              </UserProvider>
            </ProtectedRoute>
          }
        />
      </Routes>
      {!isDashboard && !isBrandDashboard && <Footer />}
    </>
  );
}

export default App;
