import React from 'react';
import Logo from '../assets/images/logo (53).png';
import { Link } from 'react-router-dom';
import './libs/@iconscout/unicons/css/line.css';

export default function Footer() {

  return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
      <div className="container relative">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="py-[60px] px-0">
              <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                <div className="lg:col-span-4 md:col-span-12">
                  <Link to="/" className="text-[22px] focus:outline-none">
                    <img src={Logo} alt="" />
                  </Link>
                  <p className="mt-6 text-gray-300">
                    Being India's best influencer marketing agency, we leverage
                    data-driven methodologies and research to accomplish your
                    goals. Skyrocket your business today!
                  </p>
                  {/* <ul className="list-none mt-6">
                    <li className="inline">
                      <Link
                        to=""
                        target="_blank"
                        className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                      >
                        <i
                          className="uil uil-facebook-f align-middle"
                          title="facebook"
                        />
                      </Link>
                    </li>
                    <li className="inline">
                      <Link
                        to=""
                        target="_blank"
                        className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                      >
                        <i
                          className="uil uil-instagram align-middle"
                          title="instagram"
                        />
                      </Link>
                    </li>
                    <li className="inline">
                      <Link
                        to=""
                        target="_blank"
                        className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                      >
                        <i
                          className="uil uil-twitter align-middle"
                          title="twitter"
                        />
                      </Link>
                    </li>
                  </ul> */}
                </div>
                {/* <div className="lg:col-span-2 md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Company
                  </h5>
                  <ul className="list-none footer-list mt-6">
                    <li>
                      <Link
                        to="/about"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b" /> About us
                      </Link>
                    </li>
                    <li className="mt-[10px]">
                      <Link
                        to="/careers"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b" /> Careers
                      </Link>
                    </li>

                  </ul>
                </div> */}
                <div className="lg:col-span-3 md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Legal
                  </h5>
                  <ul className="list-none footer-list mt-6">
                    {/* <li className='mb-2.5'>
                      <a
                        href='/docs/Terms_and_Conditions.pdf'
                        target='_blank'
                        className='text-gray-300 hover:text-gray-400 duration-500 ease-in-out'
                      >
                        <i className="uil uil-angle-right-b" /> Terms & Condition
                      </a>
                    </li> */}
                    <li className='mb-2.5'>
                      <Link
                        // to='/docs/Privacy_Policy.pdf'
                        to="/CP/legal/privacy_policy"
                        // target='_blank'
                        className='text-gray-300 hover:text-gray-400 duration-500 ease-in-out'
                      >
                        <i className="uil uil-angle-right-b" /> Privacy Policy
                      </Link>
                    </li>
                    {/* <li className='mb-2.5'>
                      <a
                        href='/docs/Brands_Policy.pdf'
                        target='_blank'
                        className='text-gray-300 hover:text-gray-400 duration-500 ease-in-out'
                      >
                        <i className="uil uil-angle-right-b" /> Brands Policy
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        href='/docs/Partners_Terms.pdf'
                        target='_blank'
                        className='text-gray-300 hover:text-gray-400 duration-500 ease-in-out'
                      >
                        <i className="uil uil-angle-right-b" /> Partners Policy
                      </a>
                    </li> */}
                  </ul>
                </div>

                {/* <div className="lg:col-span-3 md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Newsletter
                  </h5>
                  <p className="mt-6">
                    Sign up and receive the latest tips via email.
                  </p>
                  <form>
                    <div className="grid grid-cols-1">
                      <div className="my-3">
                        <label className="form-label">
                          Write your email <span className="text-red-600">*</span>
                        </label>
                        <div className="form-icon relative mt-2">
                          <i
                            data-feather="mail"
                            className="w-4 h-4 absolute top-3 start-4"
                          ></i>
                          <input
                            type="email"
                            className="form-input ps-12 rounded w-full py-2 px-3 h-10 bg-gray-800 border-0 text-gray-100 focus:shadow-none focus:ring-0 placeholder:text-gray-200"
                            placeholder="Email"
                            name="email"
                            required=""
                          />
                        </div>
                      </div>

                      <button
                        type="submit"
                        id="submitsubscribe"
                        name="send"
                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                      >
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[30px]   text-center border-t border-slate-800">
        <div className="container relative text-center">
          <div className="flex flex-row justify-center">
            <div className="md:text-start text-center">
              <p className="mb-0">
                ©All rights reserved.Crello Adtech {new Date().getFullYear()}
                {' '}
                Proudly made in India
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
