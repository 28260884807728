import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaSignInAlt, FaApple } from "react-icons/fa";
import { toast } from "react-toastify";
import { Fade } from "react-awesome-reveal";
import PuffLoader from "react-spinners/PuffLoader";
import img1 from "../../assets/auth/partner_bg.png";
import { API_URL } from "../../constant/constant";

export default function Brandlogin() {
  const [formData, setFormData] = useState({
    email_id: "",
    password_creator: "",
    userType: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const GoogleLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48">
      <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" />
      <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" />
      <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" />
      <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" />
    </svg>
  );

  const handleLogin = async () => {
    try {
      setLoading(true);

      if (!formData.email_id || !formData.password_creator || !formData.userType) {
        toast.error("Please fill in all fields including user type");
        return;
      }

      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email_id)) {
        toast.error("Please enter a valid email address");
        return;
      }

      const response = await axios.post(
        `${API_URL}/api/creatorreg/creator_login`,
        formData,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200) {
        toast.success("Login successful");

        const userType = formData.userType;
        if (userType === "Creators/Influencers") {
          await new Promise(resolve => setTimeout(resolve, 1000));
          navigate("/CP/dashboard");
        } else if (userType === "Brand/Agent") {
          await new Promise(resolve => setTimeout(resolve, 1000));
          navigate("/CP/brand-dashboard");
        }
      }
    } catch (error) {
      console.error("Login error:", error);
      if (error.response) {
        switch (error.response.status) {
          case 401:
            toast.error(error.response.data.error || "Invalid email or password");
            break;
          case 403:
            toast.error("Account not verified");
            break;
          default:
            toast.error(error.response.data?.error || "Login failed");
        }
      } else {
        toast.error("Network error. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <section className="relative flex justify-center w-full py-24 overflow-hidden bg-gradient-to-b to-transparent from-indigo-600/20 dark:from-indigo-600/40">
        <Fade cascade>
          <div className="container relative">
            <div className="relative grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
              <div className="md:col-span-6">
                <div className="md:me-8">
                  <img src={img1} alt="" />
                  <h2 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white relative">
                    Smarter way to generate Revenue
                  </h2>
                  <p className="text-slate-400 text-lg max-w-xl">
                    Are you struggling to generate revenue for your accounts? We
                    help you with generating revenue based on your audience
                    count and audience type. All you need is to be a Creator
                    with us.
                  </p>
                </div>
              </div>
              <div className="md:col-span-6 gap-5">
                <div className="relative border-[3px] border-gray-100  rounded-lg">
                  <div className="p-4">
                    <div className="mb-4">
                      <select
                        name="userType"
                        id="userType"
                        value={formData.userType}
                        onChange={handleChange}
                        className="w-full py-3 px-4 h-[50px] outline-none text-gray-700 
                            rounded-lg bg-white border border-gray-300 
                            focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500
                            shadow-sm text-base"
                      >
                        <option value="">Login as ?</option>
                        <option value="Brand/Agent">Brand/Agency</option>
                        <option value="Creators/Influencers">Creators/Influencers</option>
                        <option value="Partners/Contractors">Partners/Contractors</option>
                      </select>
                    </div>

                    <div className="mb-4">
                      <input
                        type="text"
                        id="email"
                        name="email_id"
                        value={formData.email_id}
                        onChange={handleChange}
                        className="w-full py-3 px-4 h-[50px] outline-none text-gray-700 
                            rounded-lg bg-white border border-gray-300 
                            focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500
                            shadow-sm text-base"
                        placeholder="Mobile Number/Email/Username"
                      />
                    </div>

                    <div className="mb-4">
                      <input
                        type="password"
                        id="password"
                        name="password_creator"
                        value={formData.password_creator}
                        onChange={handleChange}
                        className="w-full py-3 px-4 h-[50px] outline-none text-gray-700 
                            rounded-lg bg-white border border-gray-300 
                            focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500
                            shadow-sm text-base"
                        placeholder="Password"
                      />
                    </div>

                    <div className="mb-4">
                      <Link
                        to="/CP/forgot-password"
                        className="text-indigo-500 hover:text-indigo-600 hover:underline text-base"
                      >
                        Forgot Password ?
                      </Link>
                    </div>

                    <div className="pt-4 flex flex-col md:flex-row justify-center gap-3 md:gap-4 px-2">
                      <button
                        type="button"
                        onClick={handleLogin}
                        id="submitsubscribe"
                        name="send"
                        disabled={loading}
                        className="flex items-center justify-center gap-2 w-full md:w-auto px-6 py-2.5 
                            font-semibold tracking-wide border duration-300 text-base 
                            bg-indigo-600 hover:bg-indigo-700 border-indigo-600 
                            hover:border-indigo-700 text-white rounded-lg md:min-w-[160px]
                            disabled:opacity-70 disabled:cursor-not-allowed"
                      >
                        {loading ? (
                          <PuffLoader color="#ffffff" size={24} />
                        ) : (
                          <>
                            <FaSignInAlt className="text-lg" />
                            <span>Login</span>
                          </>
                        )}
                      </button>

                      <button
                        type="button"
                        id="googlelogin"
                        disabled
                        className="flex items-center justify-center gap-1.5 w-full md:w-auto px-6 py-2.5 
                            font-semibold tracking-wide border text-base 
                            bg-white border-gray-300 
                            text-gray-700 rounded-lg md:min-w-[160px]
                            cursor-not-allowed opacity-70"
                      >
                        <GoogleLogo />
                        Login with Google
                      </button>

                      <button
                        type="button"
                        id="facebooklogin"
                        disabled
                        className="flex items-center justify-center gap-1 w-full md:w-auto px-6 py-2.5 
                            font-semibold tracking-wide border text-base 
                            bg-white border-gray-300 
                            text-gray-700 rounded-lg md:min-w-[160px]
                            cursor-not-allowed opacity-70"
                      >
                        <FaApple className="text-3xl text-[#A2AAAD]" />
                        Login with Apple
                      </button>
                    </div>

                    <div className="text-center mt-4">
                      <p className="text-gray-600">
                        New Creator?{" "}
                        <Link
                          to="/CP/creatorregister"
                          className="text-indigo-600 hover:text-indigo-700 font-semibold hover:underline"
                        >
                          Sign up
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </div>
  );
}
