import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import axios from 'axios';
import { API_URL } from '../../constant/constant';
import { ToastContainer, toast } from "react-toastify";
import OfferDetailsModal from '../../components/OfferDetailsModal';
import he from "he";

export default function OffersDashboard() {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/affiliate/get_affiliate_campaigns`);
                // const openCampaigns = response.data.filter(campaign => campaign.status === 'open');
                setOffers(response.data.data || []);
            } catch (error) {
                toast.error('Error fetching offers');
                console.error('Error fetching offers:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchOffers();
    }, []);

    if (loading) return <p>Loading Offers...</p>;

    const openModal = (campaign) => {
        setSelectedCampaign(campaign);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedCampaign(null);
        setIsModalOpen(false);
    };

    return (
        <div className="text-gray-700">
            <ToastContainer />
            <Fade cascade>
                <h1 className="text-2xl md:text-3xl font-bold mb-2 md:mb-4">Exclusive Offers</h1>
                <p className="mb-4 md:mb-6 text-base md:text-lg">Check out the best offers available now:</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
                    {offers && offers.length > 0 ? (
                        offers.map((offer) => (
                            <div key={offer._id} className="bg-white p-3 md:p-4 shadow-md rounded-lg flex flex-col justify-between">
                                <div className='w-full h-32 md:h-40 flex items-center justify-center'>
                                    <img
                                        src={offer.logo}
                                        alt={offer.offer_name}
                                        className="w-full h-full object-contain rounded-t-lg"
                                    />
                                </div>
                                <div>
                                    <h2 className="text-lg md:text-xl font-bold mt-3 md:mt-4 text-center">{offer.offer_name}</h2>
                                    {/* <p className="text-gray-700 font-semibold uppercase text-xs md:text-sm tracking-wide mt-2 md:mt-3">
                                        {offer.brandname}
                                    </p> */}
                                    <p className="mt-2 md:mt-3 text-gray-500 text-sm md:text-base italic truncate">
                                        {he.decode(offer.offer_description)}
                                    </p>
                                </div>
                                <button
                                    className='bg-indigo-600 text-white text-sm md:text-base mt-3 md:mt-4 px-3 md:px-4 py-2 rounded w-full'
                                    onClick={() => openModal(offer)}
                                >
                                    View Offer
                                </button>
                            </div>
                        ))
                    ) : (
                        <p className="text-base md:text-lg">No offers available.</p>
                    )}
                    <OfferDetailsModal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        offer={selectedCampaign}
                    />
                </div>
            </Fade>
        </div>
    );
}