import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Outlet, NavLink, Link } from "react-router-dom";
import logo from "../assets/logo (53).png";
import { ReactComponent as Instagram } from "../assets/svg/instagram.svg";
import { FaBars, FaTimes, FaHome, FaUser, FaMoneyBill, FaHeadset, FaCog, FaBell, FaChevronDown, FaChevronUp, FaUserCircle, FaYoutube, FaPlus, FaCreditCard, FaLock, FaTag, FaCheck } from "react-icons/fa";
import { API_URL } from "../constant/constant";
import { toast } from "react-toastify";
import { UserContext } from "../Pages/Auth/UserContext";

const BrandDashboardLayout = () => {
    const { user, loading } = useContext(UserContext);
    const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
    const [isEarningsOpen, setIsEarningsOpen] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {

    }, []);

    const handleLogout = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/creatorreg/creator_logout`, {
                withCredentials: true
            });

            const domains = [
                '.bookmesocial.com',
                'bookmesocial.com',
                'localhost',
                window.location.hostname
            ];

            domains.forEach(domain => {
                document.cookie = `creator_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}; secure; samesite=none`;
            });

            toast.success(response.data.message, {
                toastId: 'logout-success'
            });

            await new Promise(resolve => setTimeout(resolve, 500));

            window.location.href = '/CP/brandlogin';
        } catch (error) {
            console.error('Logout error:', error);
            toast.error(error.response?.data?.message || "Logout failed", {
                toastId: 'logout-error'
            });
        }
    };

    return (
        <div className="flex h-dvh bg-gray-300 p-2">

            {/* Mobile Header */}
            <div className="md:hidden fixed top-0 left-0 right-0 bg-white shadow-md p-4 flex justify-between items-center z-50">
                <img className="h-8 w-auto" src={logo} alt="Your Company" />
                <button
                    className="text-black"
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                    {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                </button>
            </div>

            {/* Main Container */}
            <div className="flex w-full h-full max-w-full bg-white shadow-lg rounded-xl overflow-hidden m-auto">
                {/* Sidebar */}
                <div className={`
                    bg-[#ed2f5a]
                    md:w-64 md:block
                    ${isMobileMenuOpen ? 'absolute w-52 left-0 h-full' : 'hidden'}
                    md:relative transition-all duration-300 ease-in-out
                    z-40 md:z-0
                `}>
                    <div className="h-full p-5 overflow-y-auto">
                        {/* Sidebar Content */}
                        <div className="mb-6">
                            <img className="hidden md:block h-10 w-auto mx-auto" src={logo} alt="Your Company" />
                        </div>
                        <hr className="border-t border-x-white mb-6" />
                        <ul>
                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/CP/dashboard/home"
                                    className={({ isActive }) =>
                                        isActive ? "bg-[#d71643] p-3 rounded-md flex items-center text-white" : "hover:bg-[#d71643] p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaHome className="mr-2" />Dashboard
                                </NavLink>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/CP/dashboard/offers"
                                    className={({ isActive }) =>
                                        isActive ? "bg-[#d71643] p-3 rounded-md flex items-center text-white" : "hover:bg-[#d71643] p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaTag className="mr-2" />Offers
                                </NavLink>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <button
                                    className="flex items-center justify-between w-full text-left p-3 rounded-md text-white hover:bg-[#d71643]"
                                    onClick={() => setIsAccountDropdownOpen(!isAccountDropdownOpen)}
                                >
                                    <span className="flex items-center">
                                        <FaUserCircle className="mr-2" />
                                        Account
                                    </span>
                                    {isAccountDropdownOpen ? <FaChevronUp className="text-sm" /> : <FaChevronDown className="text-sm" />}
                                </button>
                                <div
                                    className={`transition-all duration-500 overflow-hidden ${isAccountDropdownOpen ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                                        }`}
                                >
                                    <ul className="pl-6 mt-2 space-y-2">
                                        <li>
                                            <NavLink
                                                to="/CP/dashboard/profile"
                                                className={({ isActive }) =>
                                                    isActive ? "bg-[#d71643] p-3 rounded-md flex items-center text-white" : "hover:bg-[#d71643] p-3 rounded-md flex items-center text-white"
                                                }
                                            >
                                                <FaUser className="mr-2" />
                                                Profile
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <button
                                    className="flex items-center justify-between w-full text-left p-3 rounded-md text-white hover:bg-[#d71643]"
                                    onClick={() => setIsEarningsOpen(!isEarningsOpen)}
                                >
                                    <span className="flex items-center">
                                        <FaMoneyBill className="mr-2" />
                                        Earnings
                                    </span>
                                    {isEarningsOpen ? <FaChevronUp className="text-sm" /> : <FaChevronDown className="text-sm" />}
                                </button>
                                <div
                                    className={`transition-all duration-500 overflow-hidden ${isEarningsOpen ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                                        }`}
                                >
                                    <ul className="pl-6 mt-2 space-y-2">
                                        <li>
                                            <NavLink
                                                to="/CP/dashboard/earnings"
                                                className={({ isActive }) =>
                                                    isActive ? "bg-[#d71643] p-3 rounded-md flex items-center text-white" : "hover:bg-[#d71643] p-3 rounded-md flex items-center text-white"
                                                }
                                            >
                                                <FaCreditCard className="mr-2" />
                                                Add your Bank
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/CP/dashboard/support"
                                    className={({ isActive }) =>
                                        isActive ? "bg-[#d71643] p-3 rounded-md flex items-center text-white" : "hover:bg-[#d71643] p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaHeadset className="mr-2" />
                                    Support
                                </NavLink>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <button
                                    className="flex items-center justify-between w-full text-left p-3 rounded-md text-white hover:bg-[#d71643]"
                                    onClick={() => setIsSettingsOpen(!isSettingsOpen)}
                                >
                                    <span className="flex items-center">
                                        <FaCog className="mr-2" />
                                        Settings
                                    </span>
                                    {isSettingsOpen ? <FaChevronUp className="text-sm" /> : <FaChevronDown className="text-sm" />}
                                </button>
                                <div
                                    className={`transition-all duration-500 overflow-hidden ${isSettingsOpen ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                                        }`}
                                >
                                    <ul className="pl-6 mt-2 space-y-2">
                                        <li>
                                            <NavLink
                                                to="/CP/dashboard/reset-password"
                                                className={({ isActive }) =>
                                                    isActive ? "bg-[#d71643] p-3 rounded-md flex items-center text-white" : "hover:bg-[#d71643] p-3 rounded-md flex items-center text-white"
                                                }
                                            >
                                                <FaLock className="mr-2" />
                                                Reset Password
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/CP/dashboard/notifications"
                                    className={({ isActive }) =>
                                        isActive ? "bg-[#d71643] p-3 rounded-md flex items-center text-white cursor-not-allowed" : "hover:bg-[#d71643] p-3 rounded-md flex items-center text-white cursor-not-allowed"
                                    }
                                    onClick={(e) => e.preventDefault()}
                                    aria-disabled="true"
                                >
                                    <FaBell className="mr-2" />
                                    Notifications
                                </NavLink>
                            </li>
                        </ul>

                        {/* Mobile-only buttons */}
                        <div className="md:hidden mt-6 space-y-2">
                            <button
                                onClick={handleLogout}
                                className="bg-red-500 hover:bg-red-700 text-white text-[14px] py-1.5 px-3 rounded w-full"
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </div>

                {/* Main Content Area */}
                <div className="flex-1 flex flex-col min-h-0 md:pl-0 pt-16 md:pt-0">
                    {/* Desktop Header */}
                    <header className="hidden md:flex w-full bg-[#ed2f5a] text-white justify-between items-center p-4 mb-6 rounded-tr-md">
                        <div className="text-xl font-bold mr-2">Dashboard</div>
                        <div className="flex items-center space-x-4">
                            <button
                                onClick={handleLogout}
                                className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Logout
                            </button>
                        </div>
                    </header>

                    {/* Main Content */}
                    <main className="flex-1 overflow-y-auto bg-white p-4 shadow rounded-md">
                        <Outlet />
                    </main>
                </div>
            </div>

            {/* Mobile Overlay */}
            {isMobileMenuOpen && (
                <div
                    className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-30"
                    onClick={() => setIsMobileMenuOpen(false)}
                />
            )}

        </div>
    );
};

export default BrandDashboardLayout;