import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../constant/constant";
import { UserContext } from "../Auth/UserContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as YouTubeIcon } from '../../assets/svg/youtube.svg';

const YouTube = () => {
    const { user, loading } = useContext(UserContext);
    const [submitted, setSubmitted] = useState(false);
    const [channelLink, setChannelLink] = useState("");
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        const fetchStatus = async () => {
            if (!user) return;
            try {
                const response = await axios.get(`${API_URL}/api/creatorreg/get_youtube_status`, {
                    params: { email: user.email_id },
                });

                setConnected(response.data.connected);
            } catch (error) {
                console.error("Failed to fetch connection status", error);
            }
        };

        if (!loading) {
            fetchStatus();
        }

        fetchStatus();
    }, [user, loading]);


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user) {
            toast.error("User is not authenticated");
            return;
        }

        try {
            const response = await axios.post(`${API_URL}/api/creatorreg/save_youtube_url`, {
                channelLink,
                email: user.email_id,
            });

            if (response.status === 200) {
                setSubmitted(true);
            }
        } catch (error) {
            const message = error.response?.data?.error || "An error occurred";
            toast.error(message);
        }

        setSubmitted(true);
    };

    return (
        <div className="text-gray-700 bg-indigo-100 min-h-full h-full flex flex-col items-center justify-center py-4 md:py-7 px-3 md:px-6">
            <ToastContainer autoClose={2500} />
            <div className="flex justify-center mb-6 w-full">
                <YouTubeIcon className="w-16 h-16 md:w-24 md:h-24" />
            </div>
            <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
                <h1 className="text-2xl font-bold text-center text-indigo-600 mb-6">Submit Your Channel Link</h1>

                {submitted || connected ? (
                    <div className="text-center">
                        <p className="text-lg font-medium text-green-600 mb-4">
                            Thanks for your submission! <br /> We will check and confirm your account.
                        </p>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="channelLink" className="block text-gray-700 font-medium mb-2">
                                YouTube Channel Link
                            </label>
                            <input
                                type="url"
                                id="channelLink"
                                value={channelLink}
                                onChange={(e) => setChannelLink(e.target.value)}
                                className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                placeholder="Enter your YouTube channel link"
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700"
                        >
                            Submit for Verification Process
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default YouTube;