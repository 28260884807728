import axios from "axios";
import React, { useState, useContext } from "react";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { API_URL } from "../../constant/constant";
import { toast, ToastContainer } from "react-toastify";
import { FiX } from "react-icons/fi";
import { UserContext } from "./UserContext";
import { PuffLoader } from 'react-spinners';
import Cookies from 'js-cookie';

export default function Creator_Dashboard() {

    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    const platforms = ["Youtube", "Instagram", "Facebook", "Twitter", "Moj", "Sharechat"]

    const campaign_types = ["Paid", "Barter", "Affiliate", "Full Video", "IG Reels", "YT Shortz", "Reels & Shortz", "Customized Campaigns", "Others"]

    const contract_partnerships = ["3k", "5k", "6k", "7k", "8k", "9k", "10k"]

    const payout_types = ["Barter", "Product Refund", "Fixed Pay", "Flexible pay", "Performance Based", "Affiliate"]

    const interest_topics = ["Beauty", "Education", "Women's Fashion", "Food", "Agriculture", "NEWS", "Devotional Lifestyle", "Parenting", "Photography", "Travel", "Technology", "Couple", "Adult", "Men's Fashion", "Mom", "Fitness", "Pet", "Family", "ECommerce", "Others"]

    const [formData, setFormData] = useState({
        upload_profile_image: null,
        name: "",
        dob: "",
        mobile_number: "",
        gender: "",
        channel_site_name: "",
        social_media: [],
        content_types: [""],
        content_lang: "",
        followers_range: "",
        city: "",
        state: "",
        country: "",
        campaign_types: [],
        other_campaign_type: "",
        min_rate_youtube_short: "",
        max_rate_youtube_short: "",
        min_rate_youtube_full: "",
        max_rate_youtube_full: "",
        min_rate_ig_reel: "",
        max_rate_ig_reel: "",
        other_price: "",
        contract_partnerships: [],
        upload_channel_image: null,
        payout_types: [],
        interest_topics: [],
        other_interest_topic: "",
    });

    const [profileImageName, setProfileImageName] = useState("");
    const [channelImageName, setChannelImageName] = useState("");

    const handleCountryChange = (selectedOption) => {
        setFormData({
            ...formData,
            country: selectedOption ? selectedOption.value : '',
        });
    };

    const handleStateChange = (selectedOption) => {
        setFormData({
            ...formData,
            state: selectedOption ? selectedOption.value : '',
        });
    };

    const handleCityChange = (selectedOption) => {
        setFormData({
            ...formData,
            city: selectedOption ? selectedOption.value : '',
        });
    };

    const handleContentTypeChange = (index, value) => {
        const updatedContentTypes = [...formData.content_types];
        updatedContentTypes[index] = value;
        setFormData((prevData) => ({
            ...prevData,
            content_types: updatedContentTypes,
        }));
    };

    const addContentType = () => {
        setFormData((prevData) => ({
            ...prevData,
            content_types: [...prevData.content_types, ""],
        }));
    };

    const removeContentType = (index) => {
        const updatedContentTypes = formData.content_types.filter(
            (_, i) => i !== index
        );
        setFormData((prevData) => ({
            ...prevData,
            content_types: updatedContentTypes,
        }));
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? 'black' : provided.borderColor,
            boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
            '&:hover': {
                borderColor: 'black'
            }
        })
    };

    // Get Country options
    const countryOptions = Country.getAllCountries().map((country) => ({
        value: country.isoCode,
        label: country.name
    }));

    // Get state options based on the selected country
    const stateOptions = formData.country
        ? State.getStatesOfCountry(formData.country).map((state) => ({
            value: state.isoCode,
            label: state.name
        })) : [];

    // Get city options based on selected state
    const cityOptions = formData.state
        ? City.getCitiesOfState(formData.country, formData.state).map((city) => ({
            value: city.name,
            label: city.name
        })) : [];

    // Handler for checkbox selections
    const handleCheckboxChange = (name, value) => {
        setFormData((prevData) => {
            let updatedArray = prevData[name];

            if (value === "Others") {
                if (updatedArray.includes("Others")) {
                    updatedArray = updatedArray.filter((item) => item !== "Others");

                    return {
                        ...prevData,
                        [name]: updatedArray,
                        [name === "campaign_types" ? "other_campaign_type" : "other_interest_topic"]: "",
                    };
                } else {
                    updatedArray = [...updatedArray, "Others"];
                    return {
                        ...prevData,
                        [name]: updatedArray,
                    };
                }
            } else {
                // Handle regular checkboxes
                if (updatedArray.includes(value)) {
                    updatedArray = updatedArray.filter((item) => item !== value);
                } else {
                    updatedArray = [...updatedArray, value];
                }
                return {
                    ...prevData,
                    [name]: updatedArray,
                };
            }
        });
    };


    // Handler for text input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Validation Handler
    const validateForm = () => {
        let valid = true;
        const requiredFields = [
            'name',
            'dob',
            'mobile_number',
            'channel_site_name',
            'min_rate_youtube_short',
            'max_rate_youtube_short',
            'payout_types',
            'interest_topics'
        ];

        for (const field of requiredFields) {
            if (Array.isArray(formData[field])) {
                if (formData[field].length === 0) {
                    valid = false;
                }
            } else if (!formData[field]) {
                valid = false;
            }
        }

        if (!valid) {
            toast.error("Please fill the required fields!", {
                position: "top-right",
                autoClose: 3000,
            });
        }

        return valid;
    }

    // Handler for file change
    const handleFileChange = (e, field) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({ ...formData, [field]: file });
            if (field === "upload_profile_image") setProfileImageName(file.name);
            if (field === "upload_channel_image") setChannelImageName(file.name);
        }
    };

    // Handler for "Others" input changes for campaign types
    const handleOtherCampaignTypeChange = (e) => {
        setFormData(prevData => ({
            ...prevData,
            other_campaign_type: e.target.value,
        }));
    };

    // Handler for "Others" input changes for interest topics
    const handleOtherInterestTopicChange = (e) => {
        setFormData(prevData => ({
            ...prevData,
            other_interest_topic: e.target.value,
        }));
    };

    const handleDateChange = (e) => {
        let input = e.target.value.replace(/\D/g, '');
        if (input.length >= 2) input = `${input.slice(0, 2)}/${input.slice(2)}`;
        if (input.length >= 5) input = `${input.slice(0, 5)}/${input.slice(5)}`;

        setFormData({ ...formData, dob: input });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!validateForm()) {
            setLoading(false);
            return;
        }

        try {
            const formDataToSend = new FormData();
            const token = Cookies.get('creator_token');

            if (formData.upload_profile_image) {
                formDataToSend.append('upload_profile_image', formData.upload_profile_image);
            }
            if (formData.upload_channel_image) {
                formDataToSend.append('upload_channel_image', formData.upload_channel_image);
            }

            if (!user?.email_id) {
                toast.error('User email not found', {
                    toastId: 'email-error'
                });
                return;
            }

            formDataToSend.append('email', user.email_id);

            Object.keys(formData).forEach((key) => {
                if (key !== 'upload_profile_image' && key !== 'upload_channel_image') {
                    if (Array.isArray(formData[key])) {
                        formDataToSend.append(key, JSON.stringify(formData[key]));
                    } else {
                        formDataToSend.append(key, formData[key] || '');
                    }
                }
            });

            const response = await axios.post(
                `${API_URL}/api/creatorreg/save_dashboard_details`,
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    },
                    withCredentials: true
                }
            );

            if (response.status === 200) {
                toast.success(response.data.message, {
                    toastId: 'save-success',
                    autoClose: 3000
                });
                setFormData({
                    upload_profile_image: null,
                    name: "",
                    dob: "",
                    mobile_number: "",
                    gender: "",
                    channel_site_name: "",
                    social_media: [],
                    content_types: [""],
                    content_lang: "",
                    followers_range: "",
                    city: "",
                    state: "",
                    country: "",
                    campaign_types: [],
                    other_campaign_type: "",
                    min_rate_youtube_short: "",
                    max_rate_youtube_short: "",
                    min_rate_youtube_full: "",
                    max_rate_youtube_full: "",
                    min_rate_ig_reel: "",
                    max_rate_ig_reel: "",
                    other_price: "",
                    contract_partnerships: [],
                    upload_channel_image: null,
                    payout_types: [],
                    interest_topics: [],
                    other_interest_topic: "",
                });
            }
        } catch (error) {
            console.error('Error details:', error.response?.data || error);
            toast.error(error.response?.data?.error || 'Failed to save details', {
                toastId: 'save-error',
                autoClose: 3000
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <section className="relative w-full overflow-hidden">
            <div className="w-full bg-white rounded-lg">
                <h2 className="text-2xl md:text-2xl lg:text-3xl text-center font-bold text-indigo-700 mb-4 md:mb-6">
                    Tell Me About Yourself
                </h2>

                <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                    {/* Profile Image Upload */}
                    <div className="px-4">
                        <label className="block mb-2 text-base md:text-lg font-medium text-gray-700">
                            Upload Profile Image
                        </label>
                        <div className="flex items-center flex-wrap gap-3">
                            <input
                                type="file"
                                accept="image/*"
                                name="upload_profile_image"
                                onChange={(e) => handleFileChange(e, "upload_profile_image")}
                                className="hidden"
                                id="fileUploadProfile"
                            />
                            <label
                                htmlFor="fileUploadProfile"
                                className="cursor-pointer bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition-colors"
                            >
                                Choose Image
                            </label>
                            <span className="text-gray-600 text-sm md:text-base">{profileImageName}</span>
                        </div>
                    </div>

                    {/* Basic Information */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-4">
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="w-full py-3 px-4 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                            placeholder="Enter your name *"
                        />
                        <input
                            type="text"
                            name="dob"
                            value={formData.dob}
                            onChange={handleDateChange}
                            placeholder="Date of Birth (dd/mm/yyyy) *"
                            className="w-full py-3 px-4 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                        />
                        <input
                            type="number"
                            name="mobile_number"
                            value={formData.mobile_number}
                            onChange={handleInputChange}
                            maxLength="13"
                            pattern="[0-9]+"
                            className="w-full py-3 px-4 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                            placeholder="Mobile Number *"
                        />
                    </div>

                    {/* Gender and Channel Name */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
                        <select
                            name="gender"
                            value={formData.gender}
                            onChange={handleInputChange}
                            className="w-full py-3 px-4 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                        >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>

                        <input
                            type="text"
                            name="channel_site_name"
                            value={formData.channel_site_name}
                            onChange={handleInputChange}
                            className="w-full py-3 px-4 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                            placeholder="Channel / Site Name *"
                        />
                    </div>

                    {/* Platforms */}
                    <div className="px-4">
                        <div className="flex flex-wrap gap-4">
                            {platforms.map((social_media) => (
                                <div key={social_media} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="social_media"
                                        checked={formData.social_media.includes(social_media)}
                                        onChange={() => handleCheckboxChange("platforms", social_media)}
                                        className="w-4 h-4"
                                        id={social_media}
                                    />
                                    <label htmlFor={social_media} className="ml-2 text-sm md:text-base text-gray-700">
                                        {social_media}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Content Types */}
                    {formData.content_types.map((contentType, index) => (
                        <div className="px-4 md:px-6 flex items-center gap-2" key={index}>
                            <input
                                type="text"
                                className="w-full py-3 px-4 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                placeholder="Content Type *"
                                value={contentType}
                                onChange={(e) => handleContentTypeChange(index, e.target.value)}
                            />
                            {index > 0 && (
                                <button
                                    type="button"
                                    className="text-red-500 hover:text-red-700"
                                    onClick={() => removeContentType(index)}
                                >
                                    <FiX size={24} />
                                </button>
                            )}
                        </div>
                    ))}
                    <div className="p-2 flex justify-end">
                        <button
                            type="button"
                            className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700"
                            onClick={addContentType}
                        >
                            + Add Content Type
                        </button>
                    </div>

                    {/* Content Language and Followers */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
                        <input
                            type="text"
                            name="content_lang"
                            value={formData.content_lang}
                            onChange={handleInputChange}
                            className="w-full py-3 px-4 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                            placeholder="Content Own Language *"
                        />
                        <input
                            type="text"
                            name="followers_range"
                            className="w-full py-3 px-4 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                            placeholder="Enter Followers Range*"
                            value={formData.followers_range}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* Country, State, City */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
                        <Select
                            name="country"
                            value={countryOptions.find(option => option.value === formData.country)}
                            onChange={handleCountryChange}
                            options={countryOptions}
                            placeholder="Select Country"
                            styles={customStyles}
                            className="w-full"
                        />
                        <Select
                            name="state"
                            value={stateOptions.find(option => option.value === formData.state)}
                            onChange={handleStateChange}
                            options={stateOptions}
                            placeholder="Select State"
                            styles={customStyles}
                            isDisabled={!formData.country}
                            className="w-full"
                        />
                        <Select
                            name="city"
                            value={cityOptions.find(option => option.value === formData.city)}
                            onChange={handleCityChange}
                            options={cityOptions}
                            placeholder="Select City"
                            styles={customStyles}
                            isDisabled={!formData.state}
                            className="w-full"
                        />
                    </div>

                    {/* Campaign Types */}
                    <div className="px-4">
                        <label className="block text-lg mb-3 font-medium text-gray-700">
                            Campaign Types
                        </label>
                        <div className="flex flex-wrap gap-4">
                            {campaign_types.map((campaign_type) => (
                                <div key={campaign_type} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="campaign_types"
                                        checked={formData.campaign_types.includes(campaign_type)}
                                        onChange={() => handleCheckboxChange("campaign_types", campaign_type)}
                                        className="w-4 h-4"
                                        id={`campaign_type_${campaign_type}`}
                                    />
                                    <label htmlFor={`campaign_type_${campaign_type}`} className="ml-2 text-gray-700">{campaign_type}</label>
                                    {campaign_type === "Others" && formData.campaign_types.includes("Others") && (
                                        <input
                                            type="text"
                                            value={formData.other_campaign_type}
                                            onChange={handleOtherCampaignTypeChange}
                                            className="ml-4 md:ml-4 mt-2 md:mt-0 py-1 px-3 border border-gray-300 rounded w-full md:w-auto"
                                            placeholder="Specify other campaign"
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Contract Partnership */}
                    <div className="px-4">
                        <label className="block text-lg mb-3 font-medium text-gray-700">
                            Contract Partnership
                        </label>
                        <div className="flex flex-wrap gap-4">
                            {contract_partnerships.map((contract_partnership) => (
                                <div key={contract_partnership} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="contract_partnerships"
                                        checked={formData.contract_partnerships.includes(contract_partnership)}
                                        onChange={() => handleCheckboxChange("contract_partnerships", contract_partnership)}
                                        className="w-4 h-4"
                                        id={contract_partnership}
                                    />
                                    <label htmlFor={contract_partnership} className="ml-2 text-gray-700">{contract_partnership}</label>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Upload Channel Profile Image */}
                    <div className="px-4">
                        <label className="block mb-2 text-lg font-medium text-gray-700">
                            Upload Channel Profile Image
                        </label>
                        <div className="flex items-center">
                            <input
                                type="file"
                                accept="image/*"
                                name="upload_channel_image"
                                onChange={(e) => handleFileChange(e, "upload_channel_image")}
                                className="hidden"
                                id="fileUploadChannel"
                            />
                            <label
                                htmlFor="fileUploadChannel"
                                className="cursor-pointer bg-indigo-600 text-white px-4 py-2 rounded"
                            >
                                Choose Image
                            </label>
                            <span className="ml-4 text-gray-600">{channelImageName}</span>
                        </div>
                    </div>

                    {/* Youtube Rates */}
                    <div className="px-4">
                        <label className="block mb-2 text-lg font-medium text-gray-700">
                            90 - 120 Seconds Youtube (Min - Max)
                            <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                        </label>
                        <div className="flex space-x-4">
                            <input
                                type="number"
                                name="min_rate_youtube_short"
                                value={formData.min_rate_youtube_short}
                                onChange={handleInputChange}
                                className="w-full md:w-1/2 py-2 ps-4 border border-gray-300 rounded"
                                placeholder="Min Rate (in Rs.)"
                            />
                            <input
                                type="number"
                                name="max_rate_youtube_short"
                                value={formData.max_rate_youtube_short}
                                onChange={handleInputChange}
                                className="w-full md:w-1/2 py-2 ps-4 border border-gray-300 rounded"
                                placeholder="Max Rate (in Rs.)"
                            />
                        </div>
                    </div>

                    {/* Full Video Youtube Rates */}
                    <div className="px-4">
                        <label className="block mb-2 text-lg font-medium text-gray-700">
                            4 - 10 mins Full Video Youtube (Min - Max)
                        </label>
                        <div className="flex space-x-4">
                            <input
                                type="number"
                                name="min_rate_youtube_full"
                                value={formData.min_rate_youtube_full}
                                onChange={handleInputChange}
                                className="w-full md:w-1/2 py-2 ps-4 border border-gray-300 rounded"
                                placeholder="Min Rate (in Rs.)"
                            />
                            <input
                                type="number"
                                name="max_rate_youtube_full"
                                value={formData.max_rate_youtube_full}
                                onChange={handleInputChange}
                                className="w-full md:w-1/2 py-2 ps-4 border border-gray-300 rounded"
                                placeholder="Max Rate (in Rs.)"
                            />
                        </div>
                    </div>

                    {/* IG Reel Rates */}
                    <div className="px-4">
                        <label className="block mb-2 text-lg font-medium text-gray-700">
                            60 - 90 Seconds IG Reel (Min - Max)
                        </label>
                        <div className="flex space-x-4">
                            <input
                                type="number"
                                name="min_rate_ig_reel"
                                value={formData.min_rate_ig_reel}
                                onChange={handleInputChange}
                                className="w-full md:w-1/2 py-2 ps-4 border border-gray-300 rounded"
                                placeholder="Min Rate (in Rs.)"
                            />
                            <input
                                type="number"
                                name="max_rate_ig_reel"
                                value={formData.max_rate_ig_reel}
                                onChange={handleInputChange}
                                className="w-full md:w-1/2 py-2 ps-4 border border-gray-300 rounded"
                                placeholder="Max Rate (in Rs.)"
                            />
                        </div>
                    </div>

                    {/* Others (Type) */}
                    <div className="px-4">
                        <label className="block mb-2 text-lg font-medium text-gray-700">
                            Others (Type)
                        </label>
                        <input
                            type="text"
                            name="other_price"
                            value={formData.other_price}
                            onChange={handleInputChange}
                            className="w-full py-2 ps-4 border border-gray-300 rounded"
                            placeholder="Enter other campaign type"
                        />
                    </div>

                    {/* Payout Types */}
                    <div className="px-4">
                        <label className="block text-lg mb-3 font-medium text-gray-700">
                            Payout Types
                            <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                        </label>
                        <div className="flex flex-wrap gap-4">
                            {payout_types.map((payout_type) => (
                                <div key={payout_type} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="payout_types"
                                        checked={formData.payout_types.includes(payout_type)}
                                        onChange={() => handleCheckboxChange("payout_types", payout_type)}
                                        className="w-4 h-4"
                                        id={payout_type}
                                    />
                                    <label htmlFor={payout_type} className="ml-2 text-gray-700">{payout_type}</label>
                                </div>
                            )
                            )}
                        </div>
                    </div>

                    {/* Interest Topics */}
                    <div className="px-4">
                        <label className="block mb-2 text-lg font-medium text-gray-700">
                            Interest Topics
                            <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                        </label>
                        <div className="flex flex-wrap gap-4">
                            {interest_topics.map((interest_topic) => (
                                <div key={interest_topic} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="interest_topics"
                                        checked={formData.interest_topics.includes(interest_topic)}
                                        onChange={() => handleCheckboxChange("interest_topics", interest_topic)}
                                        className="w-4 h-4"
                                        id={`interest_topic_${interest_topic}`}
                                    />
                                    <label htmlFor={`interest_topic_${interest_topic}`} className="ml-2 text-gray-700">{interest_topic}</label>
                                    {interest_topic === "Others" && formData.interest_topics.includes("Others") && (
                                        <input
                                            type="text"
                                            value={formData.other_interest_topic}
                                            onChange={handleOtherInterestTopicChange}
                                            className="ml-4 md:ml-4 mt-2 md:mt-0 py-1 px-3 border border-gray-300 rounded w-full md:w-auto"
                                            placeholder="Specify other interest"
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="px-4 md:px-6">
                        {loading ? (
                            <div className="flex justify-center">
                                <PuffLoader color="#4F46E5" size={40} />
                            </div>
                        ) : (
                            <button
                                type="submit"
                                className="w-full py-3 text-white font-bold bg-indigo-600 rounded-lg hover:bg-indigo-700"
                            >
                                Save & Submit
                            </button>
                        )}
                    </div>
                </form>
            </div>
            <ToastContainer autoClose={2000} />
        </section>

    );
}