import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../constant/constant";

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const checkUser = async (role) => {
        try {
            const currentPath = window.location.pathname;
            const publicRoutes = ['/forgot-password', '/reset-password'];
            if (publicRoutes.includes(currentPath)) {
                setLoading(false);
                return;
            }

            const response = await axios.get(`${API_URL}/api/auth/check`, {
                params: { role },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 5000
            });

            if (response.data.authenticated) {
                setUser(response.data.user);
            } else {
                setUser(null);
                console.log('Not authenticated');
            }
        } catch (error) {
            console.error('Auth check error:', error.response?.data || error);
            setUser(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const currentPath = window.location.pathname;
        let role = 'creator';

        if (currentPath.includes('admin')) {
            role = 'admin';
        } else if (currentPath.includes('brand')) {
            role = 'brand';
        }
        checkUser(role);
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser, loading, checkUser }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };