import React, { useState, useRef, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import logo from "../assets/logo (53).png";
import { Link } from 'react-router-dom';

const navigation = [
  {
    name: 'ABOUT',
    href: '/CP/about',
    current: false,
  },
  {
    name: 'BRANDS',
    href: '#',
    current: false,
    submenu: [
      {
        name: 'Solutions',
        href: '#',
        subOptions: [
          { name: 'Influencers & Creators', href: '/CP/brandregister' }
        ]
      }
    ]
  },
  {
    name: 'CREATORS',
    href: '#',
    current: false,
    submenu: [
      {
        name: 'Monetization',
        href: '#',
        subOptions: [
          { name: 'Influencers & Creators', href: '/CP/creatorregister' },
          { name: 'Partnerships', href: '/CP/creatorregister' },
        ]
      }
    ]
  },
  {
    name: 'SUPPORT',
    href: '/CP/support',
    current: false
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
  const [isSticky, setSticky] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [mobileActiveMenu, setMobileActiveMenu] = useState(null);

  const navbarRef = useRef(null);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setActiveMenu(null);
      setActiveSubMenu(null);
      setMobileActiveMenu(null);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navbarClass = isSticky ? 'sticky-navbar' : '';

  const handleMenuClick = (index) => {
    if (activeMenu === index) {
      setActiveMenu(null);
      setActiveSubMenu(null);
    } else {
      setActiveMenu(index);
      setActiveSubMenu(null);
    }
  };

  const handleSubMenuClick = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  const handleMobileMenuClick = (index) => {
    if (mobileActiveMenu === index) {
      setMobileActiveMenu(null);
    } else {
      setMobileActiveMenu(index);
    }
  };

  return (
    <Disclosure as="nav" className={`${navbarClass}`} ref={navbarRef}>
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex items-center justify-center sm:justify-start flex-1">
                <div className="flex items-center justify-start">
                  <Link to="/">
                    <img className="h-8 w-auto" src={logo} alt="Your Company" />
                  </Link>
                </div>
                <div className="hidden text-center sm:ml-6 sm:block flex-grow">
                  <div className="flex justify-center space-x-4">
                    {navigation.map((item, index) => (
                      <div key={item.name} className="relative">
                        {item.submenu ? (
                          <button
                            onClick={() => handleMenuClick(index)}
                            className={classNames(
                              item.current ? 'text-black' : 'text-black hover:text-indigo-600',
                              'rounded-md px-3 py-2 text-sm font-bold flex items-center'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                            <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                          </button>
                        ) : (
                          <Link
                            to={item.href}
                            onClick={() => {
                              setActiveMenu(null);
                              setActiveSubMenu(null);
                            }}
                            className={classNames(
                              item.current ? 'text-black' : 'text-black hover:text-indigo-600',
                              'rounded-md px-3 py-2 text-sm font-bold flex items-center'
                            )}
                          >
                            {item.name}
                          </Link>
                        )}
                        {activeMenu === index && item.submenu && (
                          <div className="absolute top-full left-0 mt-2 w-48 bg-white shadow-lg z-20">
                            {item.submenu.map((subItem, subIndex) => (
                              <div key={subItem.name} className="relative">
                                <button
                                  onClick={() => handleSubMenuClick(subIndex)}
                                  className="block w-full text-left font-bold px-4 py-2 text-md text-black hover:bg-indigo-600 hover:text-white flex items-center"
                                >
                                  {subItem.name}
                                  {subItem.subOptions && (
                                    <ChevronRightIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                                  )}
                                </button>
                                {activeSubMenu === subIndex && subItem.subOptions && (
                                  <ul className="absolute left-full top-0 mt-2 w-48 bg-white shadow-lg font-bold z-30">
                                    {subItem.subOptions.map((option, optionIndex) => (
                                      <li key={optionIndex} className='text-left py-2 px-3 hover:bg-indigo-600 hover:text-white'>
                                        <Link
                                          to={option.href}
                                          onClick={() => {
                                            setActiveMenu(null);
                                            setActiveSubMenu(null);
                                          }}
                                        >
                                          {option.name}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <button className='border font-bold text-center justify-center text-white bg-indigo-600 flex py-2 rounded-full px-4'>
                  <Link to='/CP/brandlogin'>Login</Link>
                </button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item, index) => (
                <div key={item.name} className="relative">
                  {item.submenu ? (
                    <button
                      onClick={() => handleMobileMenuClick(index)}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-black hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium flex items-center w-full'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                      <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                    </button>
                  ) : (
                    <Link
                      to={item.href}
                      onClick={() => {
                        setMobileActiveMenu(null);
                      }}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-black hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Link>
                  )}
                  {mobileActiveMenu === index && item.submenu && (
                    <div className="absolute top-full left-0 mt-2 w-full bg-white shadow-lg z-20">
                      {item.submenu.map((subItem, subIndex) => (
                        <div key={subItem.name} className="relative">
                          <button
                            onClick={() => handleSubMenuClick(subIndex)}
                            className="block w-full text-left font-bold px-4 py-2 text-md text-black hover:bg-indigo-600 hover:text-white flex items-center"
                          >
                            {subItem.name}
                            {subItem.subOptions && (
                              <ChevronRightIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                            )}
                          </button>
                          {activeSubMenu === subIndex && subItem.subOptions && (
                            <ul className="absolute left-0 top-full mt-2 w-full bg-white shadow-lg font-bold z-30">
                              {subItem.subOptions.map((option, optionIndex) => (
                                <li key={optionIndex} className='text-left py-2 px-3 hover:bg-indigo-600 hover:text-white'>
                                  <Link
                                    to={option.href}
                                    onClick={() => {
                                      setMobileActiveMenu(null);
                                      setActiveSubMenu(null);
                                    }}
                                  >
                                    {option.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              <div className="mt-4 px-2">
                <Link
                  to="/creatorregister"
                  className="block w-full text-center font-bold text-indigo-600 py-2 px-4 rounded-md border border-indigo-600"
                >
                  Become Influencer
                </Link>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}